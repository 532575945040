import React, { useEffect, useRef } from "react";
import Phaser from "phaser";
import LoadingScene from "./scripts/preloading";
import { GameScene } from "./scripts/game";
import { io } from "socket.io-client";
import { SOCKET_URL, getToken } from "../../utils/constant";

export let socketInstance = null;

const RocketGame = () => {
	const phaserGameRef = useRef(null);

	useEffect(() => {
		const IP = SOCKET_URL;
		const token = getToken();
		// window.document.getElementById('phaser-game').removeAttribute('style')
		const config = {
			type: Phaser.AUTO,
			parent: "phaser-game",
			scale: {
				mode: Phaser.Scale.FIT,
				width: 490,
				height: 300,
			},
			scene: [LoadingScene, GameScene],
			audio: {
				decode: true,
			},
		};

		const game = new Phaser.Game(config);

		socketInstance = io(IP, {
			transports: ["websocket", "polling"],
			query: {
				game: "rocket",
				authorization: token,
			},
		});

		if (phaserGameRef.current) {
			phaserGameRef.current.removeAttribute("style");
		}

		return () => {
			game.destroy(true);
			socketInstance.disconnect();
		};
	}, [phaserGameRef]);

	return (
		<>
			<div id="phaser-game" className="mt-1" ref={phaserGameRef}></div>
		</>
	);
};

export default RocketGame;
