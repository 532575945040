import React from 'react';

const FloatingWhatsAppButton = ({ isBottomMenuExist }) => {

    return (
        // is bottom menu exists then we need to manage height of whatsapp icon from bottom
        isBottomMenuExist ? <a
            href="https://wa.me/+918988824111"
            className="fixed bottom-[110px] right-4 z-50 bg-green-500 rounded-full px-3 py-2 shadow-lg hover:bg-green-600 transition duration-300"
            target="_blank"
            rel="noopener noreferrer"
        >
            <div class="fa-brands fa-whatsapp" style={{ "color": "white" }} aria-hidden="true"></div>
        </a> :
            <a
                href="https://wa.me/+918988824111"
                className="fixed bottom-[5%] right-4 z-50 bg-green-500 rounded-full px-3 py-2 shadow-lg hover:bg-green-600 transition duration-300"
                target="_blank"
                rel="noopener noreferrer"
            >
                <div class="fa-brands fa-whatsapp" style={{ "color": "white" }} aria-hidden="true"></div>
            </a>
    );
};

export default FloatingWhatsAppButton;
