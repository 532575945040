import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APP_ENV, AUTH_TOKEN, API_URL, getToken } from "../utils/constant";
import { loginUser } from "../services/backendServices";

function Login() {
	const [username, setUsername] = useState("");
	const [password, setPassowrd] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	const [inputValue, setInputValue] = useState("");
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isValueStored, setIsValueStored] = useState(false);
	const [linkError, setLinkError] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (username?.length !== 10) {
			setError("please enter valid 10 digit Number");
			setTimeout(() => {
				setError();
			}, 2000);
			return;
		} else {
			// Reset previous errors
			setError(null);
			setLoading(true);

			try {
				const res = await loginUser(username, password);

				if (!res.status) {
					setError(res.msg);
					setTimeout(() => {
						setError();
					}, 2000);
				}
				if (res.token === undefined) {
					setLoading(false);
				} else {
					localStorage.setItem(AUTH_TOKEN, `${res.token}`);
					setTimeout(() => {
						setLoading(false);
						navigate("/dashboard");
					}, 1500);
				}
			} catch (error) {
				console.log("API Catch Error", Error);
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (API_URL) {
			setIsValueStored(true);
			setLinkError("");
			setInputValue(API_URL);
		}
	}, [API_URL]);

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
		event.target.value !== API_URL
			? setIsValueStored(false)
			: setIsValueStored(true);
	};

	const handleLinkClick = () => {
		if (inputValue.trim() !== "" && inputValue !== API_URL) {
			setIsButtonLoading(true);
			setLinkError("");
			// Simulate an asynchronous operation (e.g., API request) to store data
			setTimeout(() => {
				localStorage.setItem("server-ip", inputValue);
				setIsValueStored(true);
				setIsButtonLoading(false);
			}, 1000);
		} else {
			if (inputValue.trim() === "") setLinkError("Enter value");
		}
	};

	const token = getToken();
	useEffect(() => {
		if (token) {
			navigate("/dashboard");
		}
	}, []);

	return (
		<>
			<div
				className="relative w-screen h-screen overflow-hidden text-5xl text-left text-white bg-backgound font-fs-elliot-pro"
				style={{
					backgroundImage: "url('./app-bg.svg')",
				}}
			>
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
						<span>WIN-</span>
						<span className="text-blue-main">YAR</span>
					</h2>
				</div>

				{APP_ENV === "dev" && (
					<div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
						<label className="mt-5 block text-sm font-medium leading-6 text-gray-900 px-7">
							Server ip
						</label>
						<div className="items-center justify-evenly flex">
							<input
								className={`rounded-8xs bg-gray ${isValueStored ? "w-[65%]" : "w-[70%]"
									} h-[35px] flex flex-row py-3.5 px-5 box-border text-[16px]`}
								type="text"
								placeholder="Enter your server ip"
								value={inputValue}
								onChange={handleInputChange}
							/>
							<button
								onClick={handleLinkClick}
								disabled={isButtonLoading}
								className="w-auto mt-1 rounded-md bg-orange-100 text-dark-blue px-4 py-1.5 text-sm font-semibold leading-6 text-dark-blue shadow-sm hover:bg-orange-200"
							>
								{isButtonLoading
									? "Loading"
									: isValueStored
										? "Linked"
										: "Link"}
							</button>
						</div>
						{linkError && (
							<p className="m-0 text-center text-red-500 text-xs">
								{linkError}
							</p>
						)}
					</div>
				)}

				<div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={handleSubmit}>
						<div>
							<label className="block text-sm font-medium leading-6 text-gray-900 px-7">
								Mobile No:
							</label>
							<div className="items-center justify-center flex">
								<input
									className="rounded-8xs bg-gray w-[90%] h-[35px] flex flex-row py-3.5 px-5 box-border text-[16px]"
									type="number"
									placeholder="Enter your mobile number"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
									required
								/>
							</div>
						</div>
						<div>
							<div>
								<label className="block text-sm font-medium leading-6 text-gray-900 px-7">
									Password
								</label>
							</div>
							<div>
								<div>
									<div className="relative items-center justify-center flex">
										<input
											className="rounded-8xs bg-gray w-[90%] h-[35px] flex flex-row py-3.5 px-5 box-border text-[16px]"
											id="password"
											name="password"
											type={showPassword ? "text" : "password"}
											autoComplete="current-password"
											placeholder="Enter your password"
											value={password}
											minLength="8"
											onChange={(e) => setPassowrd(e.target.value)}
											required
										/>
										<div
											className="absolute right-[25px] top-1/2 transform -translate-y-1/2 flex items-center text-gray-600"
											onClick={togglePasswordVisibility}
										>
											{showPassword ? (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth={1.5}
													stroke="currentColor"
													className="w-7 h-7"
													style={{ background: "none" }}
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
													/>
												</svg>
											) : (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth={1.5}
													stroke="currentColor"
													className="w-7 h-7"
													style={{ background: "none" }}
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
													/>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
													/>
												</svg>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="text-sm mt-3">
								<Link
									to="/forgot-password"
									href="#"
									className="font-semibold text-orange-100 hover:text-orange-200 px-7"
								>
									Forgot password?
								</Link>
							</div>
						</div>
						{API_URL && (
							<div className="justify-center flex">
								<button
									type="submit"
									className="w-[90%] mt-1 rounded-md bg-orange-100 text-dark-blue px-3 py-1.5 text-sm font-semibold leading-6 text-dark-blue shadow-sm hover:bg-orange-200"
								>
									{loading ? "Loading..." : "Login"}
								</button>
							</div>
						)}
					</form>

					{error && (
						<p className="mt-2 text-center text-red-500 text-xs">{error}</p>
					)}

					<p className="mt-10 text-center text-sm text-gray-500">
						Not a member ?
						<Link
							to="/register"
							className="font-semibold leading-6 text-orange-100 hover:text-orange-200"
						>
							{" "}
							Register
						</Link>
					</p>
				</div>
			</div>
		</>
	);
}

export default Login;
