import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LudoRulesModal from "./ludo-rules";

function LudoOption() {
	const navigate = useNavigate();
	const [ludoRulesModal, setLudoRulesModal] = useState(false)

	return (
		<div className="flex justify-center items-center h-screen w-screen">
			<div
				className="w-full h-full bg-no-repeat bg-cover"
				style={{ backgroundImage: "url('./assets/ludo/home_bg.svg')" }}
			>
				<div
					className="flex justify-end items-center mt-2 mr-2"
					style={{ width: "auto" }}
				>
					<div
						onClick={() => {
							setLudoRulesModal(true)
						}}
					>
						<img
							src={`./assets/ludo/qicon.png`}
							width={36}

						/>
					</div>

				</div>
				<div
					className="flex justify-center items-center mt-10"
					style={{ height: "44vh" }}
				>
					<img src="./assets/ludo/ludogame.svg" />
				</div>
				<div
					className="flex justify-evenly items-center mt-10"
					style={{ height: "20vh", width: "auto" }}
				>
					<div
						onClick={() => {

							navigate("/ludo-room");
						}}
					>
						<img src="./assets/ludo/playcustom.svg" />
					</div>
					<div
						onClick={() => {
							navigate("/ludo-online-play");
						}}
					>
						<img src="./assets/ludo/playonline.svg" />
					</div>
				</div>

				<div
					className="flex justify-evenly items-center mt-10"
					style={{ height: "15vh", width: "auto" }}
				>
					<div
						className="w-fit bg-no-repeat bg-inherit px-2 py-0 me-5"
						style={{
							border: "solid 5px #F5C913",
							borderRadius: "20px",
							maxWidth: "500px",
							color: "white",
							fontWeight: "bolder",
						}}
					>
						<div
							onClick={() => {
								navigate("/records/ludo");
							}}
						>
							<div className="flex justify-center items-center p-1">
								GAME HISTORY &nbsp;
								<svg
									width="11"
									height="19"
									viewBox="0 0 11 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ transform: "scaleX(-1)" }}
								>
									<path
										d="M8.51844 17.8869L0.75347 10.145C0.661304 10.0528 0.595867 9.95294 0.557157 9.84542C0.518447 9.73789 0.4994 9.62268 0.500014 9.4998C0.500014 9.37691 0.519062 9.2617 0.557157 9.15417C0.595252 9.04665 0.66069 8.9468 0.75347 8.85464L8.51844 1.08967C8.73349 0.874616 9.00231 0.76709 9.32489 0.76709C9.64747 0.76709 9.92396 0.882297 10.1544 1.11271C10.3848 1.34313 10.5 1.61194 10.5 1.91916C10.5 2.22638 10.3848 2.4952 10.1544 2.72561L3.38019 9.4998L10.1544 16.274C10.3694 16.489 10.477 16.7542 10.477 17.0694C10.477 17.3846 10.3618 17.6571 10.1313 17.8869C9.90092 18.1173 9.63211 18.2325 9.32489 18.2325C9.01767 18.2325 8.74885 18.1173 8.51844 17.8869Z"
										fill="#F5C913"
										stroke="#F5C913"
										stroke-width="1"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div
						className="w-fit bg-no-repeat bg-inherit px-2 py-0 me-3"
						style={{
							border: "solid 5px #F5C913",
							borderRadius: "20px",
							maxWidth: "500px",
						}}
					>
						<div
							onClick={() => {
								navigate("/");
							}}
						>
							<img
								src={`./assets/ludo/u-turn.svg`}
								width={26}
								style={{ borderRadius: "20px" }}
							/>
						</div>
					</div>
				</div>
			</div>
			<LudoRulesModal ludoRulesModal={ludoRulesModal} setLudoRulesModal={setLudoRulesModal} />
		</div>
	);
}

export default LudoOption;
