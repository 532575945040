import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "../utils/constant";

function PrivateRoute({ component: Component }) {
	const loggedInUser = getToken();
	return loggedInUser ? (
		<Suspense
			fallback={
				<div
					className="relative w-screen h-screen overflow-hidden text-left text-white bg-dark-blue font-fs-elliot-pro flex justify-center items-center"
					style={{ fontSize: "30px" }}
				>
					<div className="flex flex-row items-center justify-between h-[60px] px-4 md:px-12 gap-[200px]">
						<i className="relative font-extrabold whitespace-nowrap">
							<span>WIN-</span>
							<span className="text-blue-main">YAR</span>
						</i>
					</div>
				</div>
			}
		>
			<Component />
		</Suspense>
	) : (
		<Navigate to="/" />
	);
}

export default PrivateRoute;
