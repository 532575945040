// import jwt_decode from "jwt-decode";
import {
	AUTH_TOKEN,
	API_URL,
	getUserName,
	USER_DATA,
	removeLocalStorage,
} from "../utils/constant";

export async function makeGetApiRequest(path) {
	try {
		const response = await fetch(`${API_URL}${path}`, {
			headers: { Authorization: localStorage.getItem(AUTH_TOKEN) },
		});
		let ret_data = await response.json();
		if (response.status === 401) {
			removeLocalStorage();
			setTimeout(() => {
				window.location.href = "/login";
			}, 500);

			return;
		}
		return ret_data;
	} catch (error) {
		console.error(error);
		return {
			error: {
				title: "Api request error",
				message: "Api request error",
				error: error,
			},
		};
	}
}

export async function makeApiRequest(path, params) {
	try {
		var reqHeader = new Headers();
		reqHeader.append("Authorization", localStorage.getItem(AUTH_TOKEN));
		reqHeader.append("Content-Type", "application/json");
		const response = await fetch(`${API_URL}${path}`, {
			method: "POST",
			body: JSON.stringify(params),
			headers: reqHeader,
			// mode:'no-cors',
			redirect: "follow",
		});
		const rresponse = await response.json();
		if (response.status === 401) {
			removeLocalStorage();
			setTimeout(() => {
				window.location.href = "/login";
			}, 500);
			return;
		}
		if (!rresponse.error) {
			return { result: rresponse };
		} else {
			return { error: rresponse.error.data };
		}
	} catch (error) {
		console.error(error);
		return {
			error: {
				title: "Api request error",
				message: "Api request error",
				error: error,
			},
		};
	}
}

// V2 : send OTP Request
export async function sendOTPRequest(username, is_forgot_pass) {
	const data = {
		user_name: username,
		is_forgot_pass: is_forgot_pass,
	};
	const response = await makeApiRequest("/user/send-sms", data);
	if (response) {
		// console.log("resulte", response);
		return response.result;
	} else if (response.error) {
		throw new Error(response.error.message);
	} else {
		throw new Error("Unknown error. Please try again.");
	}
}

// V2 : Register API
export async function registerUser(username, password, refferalcode, otp) {
	const data = {
		user_name: username,
		user_password: password,
		user_referral_by: refferalcode,
		otp: Number(otp),
	};
	const response = await makeApiRequest("/auth/registerUser", data);
	if (response) {
		return response.result;
	} else if (response.error) {
		throw new Error(response.error.message);
	} else {
		throw new Error("Unknown error. Please try again.");
	}
}

// V2 : Login API
export async function loginUser(username, password) {
	const data = {
		user_name: username,
		user_password: password,
	};

	const response = await makeApiRequest("/auth/login", data);
	if (response) {
		return response.result;
	} else if (response.error) {
		throw new Error(response.error.message);
	} else {
		throw new Error("Unknown error. Please try again.");
	}
}

// V2 : Forgot Password  API
export async function forgotPassword(username, password, otp) {
	const data = {
		user_name: username,
		newpassword: password,
		otp: Number(otp),
	};
	const response = await makeApiRequest("/user/forgotpass", data);
	if (response) {
		return response.result;
	} else if (response.error) {
		throw new Error(response.error.message);
	} else {
		throw new Error("Unknown error. Please try again.");
	}
}

// V2 : fetch Dashboard
export async function getDashboardData() {
	const username = getUserName();
	const data = {
		game_name: username,
	};
	const response = await makeApiRequest("/user/dashboard", data);
	if (response) {
		if (response.result.status) {
			localStorage.setItem(USER_DATA, JSON.stringify(response.result));
		}
		return response.result;
	} else if (response.error) {
		throw new Error(response.error.message);
	} else {
		throw new Error("Unknown error. Please try again.");
	}
}

// V2 : fetch ludo player gamesession status
export async function fetchLudoPlayerStatus() {
	const response = await makeApiRequest("/ludo/getgamesession");
	if (response) {
		return response.result;
	} else if (response.error) {
		throw new Error(response.error.message);
	} else {
		throw new Error("Unknown error. Please try again.");
	}
}

// V4 : Get ludo contest
export async function fetchLudoContest() {
	const response = await makeGetApiRequest("/ludo/getalltables");
	if (response) {
		return response;
	} else if (response.error) {
		throw new Error(response.error.message);
	} else {
		throw new Error("Unknown error. Please try again.");
	}
}

// V4 : set paymne type
export async function updatePaymentType(user_payment_type) {
	const username = getUserName();
	const data = {
		user_name: username,
		user_payment_type: user_payment_type,
	};

	const response = await makeApiRequest("/user/updatePaymentType", data);
	if (response) {
		return response.result;
	} else if (response.error) {
		throw new Error(response.error.message);
	} else {
		throw new Error("Unknown error. Please try again.");
	}
}

// V4 : Get Paymant Type
export async function getPaymantType() {
	const username = getUserName();
	const data = {
		user_name: username,
	};

	const response = await makeApiRequest("/user/getUserPaymentType", data);
	if (response) {
		return response.result;
	} else if (response.error) {
		throw new Error(response.error.message);
	} else {
		throw new Error("Unknown error. Please try again.");
	}
}

// V4 : Get Game History
export async function fetchGameHistory(gameName) {
	const data = {
		game_name: gameName,
	};

	const response = await makeApiRequest("/user/gamehistory", data);
	if (response) {
		return response.result;
	} else if (response.error) {
		throw new Error(response.error.message);
	} else {
		throw new Error("Unknown error. Please try again.");
	}
}
