import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CopyIcon } from "./copy-icon.svg";
import Layout from "../../shared/layout";
import SubHeader from "../../shared/sub-header";

import {
	API_URL,
	getToken,
	notifyError,
	removeLocalStorage,
} from "../../utils/constant";

function AddBalance() {
	const location = useLocation();
	const [copySuccess, setCopySuccess] = useState(null);
	const [paymentInfo, setPaymentInfo] = useState(null);

	const handleCopy = (text) => {
		const textField = document.createElement("textarea");
		textField.innerText = text;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		document.body.removeChild(textField);

		setCopySuccess(text);
		setTimeout(() => setCopySuccess(null), 2000);
	};
	const navigate = useNavigate();

	const rechargeAmount = location.state.amount;
	const upiId = location.state.upiID;
	const amount = rechargeAmount;
	const upiLink = `upi://pay?pa=${encodeURIComponent(
		upiId
	)}&pn=winyar&mc=YourMerchantCode&tid=YourTransactionId&tn=Winyar Recharge&am=${amount}&cu=INR`;

	const qrCodeImageUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
		upiLink
	)}`;

	const openUpiApp = () => {
		const a = document.createElement("a");
		a.href = upiLink;
		// Attempt to open the UPI link
		try {
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		} catch (error) {
			notifyError("Error opening UPI app. Please try again later.");
		}
	};

	useEffect(() => {
		async function getPaymantInfo() {
			const IP = API_URL;
			const authtoken = getToken();
			const apiUrl = "/gameconfigs/upiid";

			try {
				const response = await fetch(IP + apiUrl, {
					method: "GET",
					headers: {
						authorization: authtoken,
						"Content-Type": "application/json",
					},
				});
				const data = await response.json();

				if (response.status === 401) {
					notifyError(data.msg);
					removeLocalStorage();
					setTimeout(() => {
						navigate("/login");
					}, 500);
				}

				if (!response.ok) {
					return response.status;
				}

				setPaymentInfo(data.data[0]);
			} catch (error) {
				console.error("Error fetching payment data from API:", error);
			}
		}

		getPaymantInfo();
	}, []);

	return (
		<>
			<Layout>
				<SubHeader subTitle="Recharge" showRule={false} backLink="/recharge" />
				<div className="relative p-2 mt-5 text-base text-center rounded-lg text-lightslategray font-fs-elliot-pro bg-darkslategray p-[10px]">
					<div className="text-start text-orange-100 mb-3 mt-1 ">
						1 ] MAKE PAYMENT TO UPI ID
					</div>
					<div className="flex flex-col items-start justify-start gap-[5px]  text-white text-sm">
						<div className="ml-4">
							Amount
							<span className="ml-2 mr-3">:</span>
							<span
								className="text-blue-main"
								onClick={() => handleCopy(location.state.amount)}
							>
								{location.state.amount}
								<CopyIcon className="inline ml-2 " />
							</span>
						</div>
						<div className="ml-4">
							UPI ID
							<span className="ml-5 mr-3">:</span>
							<span
								className="text-blue-main"
								onClick={() => handleCopy(paymentInfo?.upi_id)}
							>
								{paymentInfo?.upi_id}
								<CopyIcon className="inline ml-2 " />
							</span>
						</div>
						{copySuccess && (
							<p className="text-green-500">Copied: {copySuccess}</p>
						)}
					</div>
					<div className="text-start text-orange-100 mb-3 mt-10">
						2 ] TAKE FULL  SCREENSHOT
					</div>
					<li className="text-white text-start text-sm ml-4">Payment Details</li>
					<li className="text-white text-start  text-sm ml-4">Valid UTR or Transaction ID</li>


					<div className="text-start text-orange-100 mb-3 mt-10">
						3 ] CLICK VERIFY BUTTON
					</div>

					<div className="justify-center flex">
						<button
							className="w-[90%] rounded-md bg-orange-100 text-black px-3 py-3 text-sm font-semibold text-5xl shadow-sm mb-3"
							onClick={() =>
								navigate("/payment", {
									state: { amount: location.state.amount },
								})
							}
						>
							Verify Payment
						</button>
					</div>
				</div>
				{/* <div className="flex justify-center items-center my-4"> OR</div> */}
				{/* <div className="flex justify-center items-center">
          <div
            className="relative p-2 max-w-[70%] flex justify-center items-center"
            onClick={openUpiApp}
          >
            <img src={qrCodeImageUrl} alt="QR Code" width="80%" height="auto" /> */}
				{/* <img src={qrCodeImageUrl} alt="UPI QR Code" /> */}
				{/* </div>
        </div> */}
				{/* <div className="justify-center flex">
          <button
            onClick={openUpiApp}
            className="w-[90%] mt-5 rounded-md bg-orange-100 text-dark-blue px-3 py-3.5 text-sm font-semibold text-5xl text-dark-blue shadow-sm hover:bg-orange-200"
          >
            Pay Now !
          </button>
        </div> */}
			</Layout>
		</>
	);
}

export default AddBalance;
