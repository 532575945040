import { useEffect, useState } from "react";
import React from "react";
import { formatTime } from "../../shared/format-time";
import { notifyError } from "../../utils/constant";
import { fetchGameHistory } from "../../services/backendServices";

export const HiloMyOrders = () => {
	const [gameHistory, setGameHistory] = useState([]);

	useEffect(() => {
		const gameName = "hilo";

		fetchGameHistory(gameName)
			.then((res) => {
				if (res.status) {
					notifyError(res.msg);
				} else {
					setGameHistory(res.game_data);
				}
			})
			.catch((error) => {
				console.error("Error fetching andarbahar game history:", error);
			});
	}, []);

	return gameHistory?.length === 0 ? (
		<div className="p-4 text-xs flex justify-center">No records</div>
	) : (
		gameHistory?.map((current, index) => {
			return (
				<div
					key={index}
					className="my-4 rounded-xl flex flex-col items-center justify-center text-xs border-[1px] border-solid border-white"
				>
					<div className="flex flex-row mt-3 items-end justify-start gap-[20px]">
						<div
							className={`rounded-8xs ${
								current.is_win ? "bg-wingreen" : "bg-tomato"
							} w-[52px] h-[25px] flex flex-row py-3.5 px-5 box-border items-center justify-center text-center text-3xs `}
						>
							<b className="relative">{current.is_win ? "WIN" : "LOSE"}</b>
						</div>
						<div className="flex flex-row py-1.5 px-3.5 items-center justify-center">
							<div className="relative">Points: {current.betting_amount}</div>
						</div>
						<div className="flex flex-row py-1.5 px-3.5 items-center justify-center">
							<div className="relative">{formatTime(current.end_time)}</div>
						</div>
					</div>
					<div className="my-3 w-80 overflow-x-auto">
						<HistorySequncialCards
							allCard={current.card_data}
							side={current.direction_data}
						/>
					</div>

					<div className="flex items-start justify-center gap-[20px]"></div>
					<div className="flex flex-row items-center justify-center gap-[23px]">
						<div className="flex flex-row py-1.5 px-3.5 items-center justify-center">
							<div className="relative">
								Pass: {current.card_data?.length - 1}
							</div>
						</div>
						<div className="flex flex-row py-1.5 px-3.5 items-center justify-center">
							<div className="relative">
								Multiple:{" "}
								{current.is_win
									? (current.winning_amount / current.betting_amount)?.toFixed(
											2
									  )
									: "0"}
								X
							</div>
						</div>
						<div className="flex flex-row py-1.5 px-3.5 items-center justify-center">
							<div
								className={`relative  ${
									current.is_win ? "text-lightgreen" : "text-tomato"
								}`}
							>
								Rs.
								{!current.is_win ? "00.00" : current.winning_amount?.toFixed(2)}
							</div>
						</div>
					</div>
				</div>
			);
		})
	);
};

const HistorySequncialCards = ({ allCard, side }) => {
	return (
		<div className="mx-4 flex items-center justify-center h-[100px] text-[15.08px] overflow-x-auto">
			<div className="flex items-center justify-start">
				{allCard?.map((cardData, index) => {
					const data = JSON.parse(cardData);
					{
						/* const side = JSON.parse(sideData); */
					}

					let arrowImg = null;
					if (index !== 0) {
						if (
							JSON.parse(side[index - 1])?.side === "up" &&
							JSON.parse(side[index - 1])?.is_true === true
						) {
							arrowImg = "./assets/arrows/green-arrow-up.svg";
						} else if (
							JSON.parse(side[index - 1])?.side === "down" &&
							JSON.parse(side[index - 1])?.is_true === true
						) {
							arrowImg = "./assets/arrows/green-arrow-down.svg";
						} else if (
							JSON.parse(side[index - 1])?.side === "down" &&
							JSON.parse(side[index - 1])?.is_true === false
						) {
							arrowImg = "./assets/arrows/red-arrow-down.svg";
						} else if (
							JSON.parse(side[index - 1])?.side === "up" &&
							JSON.parse(side[index - 1])?.is_true === false
						) {
							arrowImg = "./assets/arrows/red-arrow-up.svg";
						}
					}

					return (
						<React.Fragment key={index}>
							{index !== 0 && (
								<img
									className="relative w-[33px] h-[34px] -mx-1 z-10"
									alt=""
									src={arrowImg}
								/>
							)}
							<div className="relative w-[70.93px] h-[92px]">
								<img
									src={
										data?.card?.type === "h"
											? "./assets/cards/dil.svg"
											: data?.card?.type === "d"
											? "./assets/cards/charkat.svg"
											: data?.card?.type === "s"
											? "./assets/cards/kali.svg"
											: "./assets/cards/fali.svg"
									}
									alt="cards"
									height="100%"
									width="100%"
								/>
								<b
									className={`absolute top-[36.63px] ${
										data?.card?.val == 1 && "left-[calc(50%_-_4.47px)]"
									}
           

${data?.card?.val < 10 && data?.card?.val > 1 && "left-[calc(50%_-_4.47px)]"}
                          ${
														data?.card?.val == 10 && "left-[calc(50%_-_9.47px)]"
													}
                          ${
														data?.card?.val == 11 && "left-[calc(50%_-_3.47px)]"
													} ${
										data?.card?.val == 12 && "left-[calc(50%_-_6.47px)]"
									} 
${data?.card?.val == 13 && "left-[calc(50%_-_5.47px)]"} 
`}
								>
									{data?.card?.val == 11
										? "J"
										: data?.card?.val == 12
										? "Q"
										: data?.card?.val == 13
										? "K"
										: data?.card?.val == 1
										? "A"
										: data?.card?.val}
								</b>
							</div>
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};
