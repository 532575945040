import AndarBaharGameCards from "../components/andar-bahar/cards";
import GameLayout from "../shared/game-layout";
// import AndarBaharRecords from "../components/andar-bahar/records";

import SubHeader from "../shared/sub-header";
const AndarBaharPage = () => {
  return (
    <>
      <GameLayout>
        <SubHeader subTitle="Andar Bahar" showRule />
        <div className="overflow-y-auto flex flex-col md:flex-row items-start justify-center w-full">
          <AndarBaharGameCards />
          {/* <AndarBaharRecords /> */}
        </div>
      </GameLayout>
    </>
  );
};

export default AndarBaharPage;
