import React from "react";
import Header from "./header";

const GameLayout = ({ children }) => {
	return (
		<div
			className="relative w-screen h-screen overflow-hidden text-5xl text-left text-white bg-backgound font-fs-elliot-pro"
			style={{
				backgroundImage: "url('./app-bg.svg')",
			}}
		>
			<Header />
			<main className="h-screen flex w-screen overflow-hidden mb-5">
				<div className="w-screen  overflow-x-hidden overflow-y-auto p-4 md:p-4">
					{children}
				</div>
			</main>
		</div>
	);
};

export default GameLayout;
