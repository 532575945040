import React, { useState } from "react";

const Tabs = ({ EveryoneContent, MyOrdersContent }) => {
  const [activeTab, setActiveTab] = useState("everyone");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="relative rounded-xl flex justify-center bg-darkslategray my-4 h-fit">
      <div className="p-4 w-80 md:w-96">
        <div className="flex items-center text-lg">
          <div
            onClick={() => handleTabChange("everyone")}
            className={`flex-1 box-border flex p-1.5 md:p-2.5 items-center justify-center border-b-2 border-solid ${
              activeTab === "everyone" ? "border-blue-main" : "border-dark-blue"
            }`}
          >
            <div className="relative whitespace-nowrap">Everyone orders</div>
          </div>
          <div
            onClick={() => handleTabChange("myOrders")}
            className={`flex-1 box-border flex p-1.5 md:p-2.5 items-center justify-center border-b-2 border-solid ${
              activeTab === "myOrders" ? "border-blue-main" : "border-dark-blue"
            }`}
          >
            <div className="relative">My orders</div>
          </div>
        </div>

        {activeTab === "everyone" ? EveryoneContent : MyOrdersContent}
      </div>
    </div>
  );
};

export default Tabs;
