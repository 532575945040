import React from "react";

const ParityMsgModel = ({ showModal, setShowModal, msg }) => {
	const handleClose = () => {
		setShowModal(false);
	};
	return (
		<>
			{showModal ? (
				<>
					<div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
						<div className="relative w-auto md:max-w-3xl md:mx-auto md:my-6 min-w-[80%]">
							<div className="relative p-3 border-0 rounded-lg shadow-lg outline-none md:w-full rounded-t-xl bg-darkslategray focus:outline-none">
								<div className="flex items-start justify-between p-3 border-b border-gray-300 border-solid rounded-t ">
									<h3 className="text-3xl m-0 font=semibold text-white text-center">
										<b className="">Parity</b>
									</h3>
									<button
										className="float-right text-black bg-transparent border-0"
										onClick={handleClose}
									>
										<span className="block w-6 h-6 py-0 text-xl text-white bg-gray-400 rounded-full opacity-7">
											x
										</span>
									</button>
								</div>
								<div className="text-lg ">
									<div className="m-3">{msg}</div>
									<div
										className={`m-2 rounded-3xs bg-wingreen
										h-[58px] flex flex-row py-3 px-5 box-border items-center justify-center text-center text-base`}
										onClick={handleClose}
									>
										<b className="relative leading-[130.4%]">OK</b>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

export default ParityMsgModel;
