import React from "react";
import { useNavigate } from "react-router-dom";
import { checkUserBlockStatus } from "../utils/constant";

const OutlineButton = ({ text, children, isBlock, setIsBlock, ...rest }) => {
	const navigate = useNavigate();
	const isBlocked = checkUserBlockStatus();

	const handleClick = () => {
		if (isBlocked) {
			setIsBlock(true);
		} else {
			if (text === "Withdraw") {
				navigate("/withdraw");
			}
		}
	};

	return (
		<button
			{...rest}
			className="rounded-mini box-border w-36 flex flex-row py-3.5 px-5 bg-transparent items-center justify-center border-[1px] border-solid border-white"
			onClick={handleClick}
		>
			<b className="relative text-white">{text || children}</b>
		</button>
	);
};

export default OutlineButton;
