// Loading scene
import Phaser from "phaser";
export default class LoadingScene extends Phaser.Scene {
	constructor() {
		super({ key: "LoadingScene" });
	}

	preload() {
		// console.log("LoadingScene");
		// Preload assets needed for the loading scene here
		this.load.image("background", "assets/rocket/background.png");
		// Preload assets needed for the gameplay scene here
		this.load.image("player1", "assets/rocket/rocket-0.svg");
		this.load.image("player2", "assets/rocket/rocket-1.svg");
		this.load.audio("blastAudio", "assets/rocket/explosion.wav");
		this.load.audio("rocketAudio", "assets/rocket/rocket_sound.mp3");
		this.load.image("waitAnim", "assets/rocket/wait.svg");
		this.load.image("glow", "assets/rocket/glow.png");
		this.load.audio("gameSound", "assets/rocket/game_sound.mp3");
	}

	create() {
		// Set up loading progress bar or other UI elements here
		// console.log("bg added");
		// this.add.image(0, 0, "background").setOrigin(1, 0.5);
		// Start the actual game scene
		// this.scene.start('MenuScene')
		this.scene.start("GameScene");
	}
}
