import React from "react";
import Header from "./header";
import Menubar from "./menu-bar";
import { useLocation } from "react-router-dom";
import FloatingWhatsAppButton from "./FloatingWhatsAppButton";

const Layout = ({ children }) => {
	const path = useLocation();
	const isLudoPath = path.pathname.includes("/records/ludo");
	const isShowWhatAppIcon = path.pathname == "/dashboard" || path.pathname == "/upi-payment" || path.pathname == "/crypto-payment" || path.pathname == "/add-money" || path.pathname == "/payment";

	return (
		<div
			className="relative w-screen h-screen overflow-hidden text-5xl text-left text-white bg-backgound font-fs-elliot-pro"
			style={{
				backgroundImage: "url('./app-bg.svg')",
			}}
		>
			<Header />
			<main
				className={`${isLudoPath ? "h-[calc(100vh)]" : "h-[calc(100vh_-_60px)]"
					} flex w-screen overflow-hidden`}
			>
				{isLudoPath ? (
					""
				) : (
					<div className=" w-0 md:w-[74px] h-[calc(100vh_-_60px)]">
						<Menubar />
					</div>
				)}
				<div className="w-screen md:w-[calc(100vw_-_74px)] max-h-[calc(100vh_-_60px)] overflow-x-hidden overflow-y-auto p-4 mb-20 md:mb-2  md:p-4">
					{children}
				</div>
			</main>
			{isShowWhatAppIcon ? <FloatingWhatsAppButton isBottomMenuExist={true} /> : null}
		</div>
	);
};

export default Layout;
