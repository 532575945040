import { useState } from "react";
import { ReactComponent as CopyIcon } from "../components/recharge/copy-icon.svg";
import Layout from "../shared/layout";
import SubHeader from "../shared/sub-header";
function AddBalance() {
	const [copySuccess, setCopySuccess] = useState(null);

	const handleCopy = (text) => {
		const textField = document.createElement("textarea");
		textField.innerText = text;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		document.body.removeChild(textField);

		setCopySuccess(text);
		setTimeout(() => setCopySuccess(null), 2000);
	};


	return (
		<Layout>
			<div className="m-5 flex justify-center items-center text-xl">
				Recharge
			</div>
			<div className="relative p-4 mt-5 text-base text-center rounded-lg text-lightslategray font-fs-elliot-pro bg-darkslategray">
				<div className="text-start text-orange-100 mb-3 mt-1">
					For Balance Recharge, Contact our support on WhatsApp !
				</div>
				<div className="flex flex-col items-start justify-start gap-3 text-white text-sm">
					<div className="ml-4">
						<span>WhatsApp No. :</span>
						<span
							className="ml-5 mr-3 text-blue-main cursor-pointer"
							onClick={() => handleCopy("8988824111")}
						>
							+91 8988824111
							<CopyIcon className="inline ml-2" />
						</span>
					</div>
					{copySuccess && (
						<p className="ml-5 mr-3 text-green-500 text-xs mt-0 mb-0">Copied: {copySuccess}</p>
					)}
				</div>
				<div className="my-2 font-bold text-white opacity-80">OR</div>
				<div className="flex justify-center">
					<button
						className="w-11/12 rounded-md bg-green-500 hover:bg-green-600 text-white px-3 py-3 text-sm font-semibold shadow-sm mb-3 transition duration-300 flex items-center justify-center"
						onClick={() => window.open("https://wa.me/+918988824111", "_blank", "noopener noreferrer")}
					>
						Recharge Now
						<span className="ml-2">
							<i className="fa-brands fa-whatsapp" style={{ color: "white" }} aria-hidden="true"></i>
						</span>
					</button>
				</div>


			</div>
		</Layout>
	);
}

export default AddBalance;
