import React from "react";

const ResultModal = ({
	showModal,
	setShowModal,
	res,
	setGameOn,
	setGameResult,
	setAllCard,
	allCard,
	gameResulte,
}) => {
	const handleClose = () => {
		setShowModal(false);
		setGameOn(false);
		setAllCard([]);
		setGameResult(null);
	};
	return (
		<>
			{showModal ? (
				<>
					<div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
						<div className="relative w-auto md:max-w-3xl md:mx-auto md:my-6 min-w-[80%] max-w-[93%]">
							<div className="relative p-3 border-0 rounded-lg shadow-lg outline-none md:w-full rounded-t-xl bg-darkslategray focus:outline-none">
								<div className="flex items-start justify-between p-3 border-b border-gray-300 border-solid rounded-t ">
									<h3 className="text-3xl m-0 font=semibold text-white text-center">
										<b className="">HiLo</b>
									</h3>
									<button
										className="float-right text-black bg-transparent border-0"
										onClick={handleClose}
									>
										<span className="block w-6 h-6 py-0 text-xl text-white bg-gray-400 rounded-full opacity-7">
											x
										</span>
									</button>
								</div>

								<div className="my-4 mx-4 flex items-center justify-center h-[100px] text-[15.08px] overflow-x-auto">
									<div className="flex items-center justify-start">
										{allCard?.map((data, index) => {
											let arrowImg = null;
											if (data.side === "up") {
												arrowImg = "./assets/arrows/green-arrow-up.svg";
											} else if (data.side === "down") {
												arrowImg = "./assets/arrows/green-arrow-down.svg";
											}
											if (gameResulte !== null) {
												if (
													data.side === "up" &&
													gameResulte.result === "lose" &&
													allCard?.length - 1 === index
												) {
													arrowImg = "./assets/arrows/red-arrow-down.svg";
												} else if (
													data.side === "down" &&
													gameResulte.result === "lose" &&
													allCard?.length - 1 === index
												) {
													arrowImg = "./assets/arrows/red-arrow-up.svg";
												}
											}
											return (
												<React.Fragment key={index}>
													{data.side !== null ? (
														<img
															className="relative w-[33px] h-[34px] -mx-1 z-10"
															alt=""
															src={arrowImg}
														/>
													) : null}
													<div className="relative w-[70.93px] h-[92px]">
														<img
															src={
																data.type === "h"
																	? "./assets/cards/dil.svg"
																	: data.type === "d"
																	? "./assets/cards/charkat.svg"
																	: data.type === "s"
																	? "./assets/cards/kali.svg"
																	: "./assets/cards/fali.svg"
															}
															alt="cards"
															width="100%"
															height="100%"
														/>
														<b
															className={`absolute top-[38.63px] ${
																data.card == 1 && "left-[calc(50%_-_4.47px)]"
															}
           

${data.card < 10 && data.card > 1 && "left-[calc(50%_-_4.47px)]"}
                          ${data.card == 10 && "left-[calc(50%_-_9.47px)]"}
                          ${data.card == 11 && "left-[calc(50%_-_3.47px)]"} ${
																data.card == 12 && "left-[calc(50%_-_6.47px)]"
															} 
${data.card == 13 && "left-[calc(50%_-_5.47px)]"}`}
														>
															{data.card == 11
																? "J"
																: data.card == 12
																? "Q"
																: data.card == 13
																? "K"
																: data.card == 1
																? "A"
																: data.card}
														</b>
													</div>
												</React.Fragment>
											);
										})}
									</div>
								</div>
								<div className="text-lg ">
									<div
										className={`m-2 rounded-3xs ${
											res.result === "win" ? "bg-wingreen" : "bg-crimson"
										}  h-[58px] flex flex-row py-3.5 px-5 box-border items-center justify-center text-center text-base`}
										onClick={handleClose}
									>
										<b className="relative leading-[130.4%]">{`you ${
											res.result
										}:
                    ${res.amount?.toFixed(2)}`}</b>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

export default ResultModal;
