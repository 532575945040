import React from "react";

const AviatorLayout = ({ children }) => {
	return (
		<div className="relative w-screen h-screen overflow-hidden text-5xl text-left text-white bg-black font-fs-elliot-pro">
			<header className="w-screen h-[60px] bg-black ">
				<div className="max-w-7xl mx-auto">
					<div className="flex flex-row items-center justify-between h-[60px] px-4 md:px-12 gap-[200px]">
						<i className="relative font-extrabold whitespace-nowrap">
							<span>WIN-</span>
							<span className="text-blue-main">YAR</span>
						</i>
					</div>
				</div>
			</header>
			<main className="h-screen flex w-screen overflow-hidden mb-5">
				<div className="w-screen  overflow-x-hidden overflow-y-auto p-4 md:p-4">
					{children}
				</div>
			</main>
		</div>
	);
};

export default AviatorLayout;
