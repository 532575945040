export const formatTime = (timeString) => {
  const date = new Date(timeString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  // Format day and month to have leading zero if necessary
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Format hours to 12-hour clock and add leading zero if necessary
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedMonth}/${formattedDay} ${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const formatDate = (timeString) => {
  const date = new Date(timeString);
  const day = date.getDate();
  const month = date.getMonth() + 1;

  // Format day and month to have leading zero if necessary
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedMonth}/${formattedDay}`;
};

export const formatOnlyTime = (timeString) => {
  const date = new Date(timeString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  // Format hours to 12-hour clock and add leading zero if necessary
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return ` ${formattedHours}:${formattedMinutes} ${ampm}`;
};
