import React, { useState } from "react";
import { socketInstance } from "./parityDesign";

const ParityBetModel = ({
	handleUserSelectValueOnModelClose,
	showModal,
	setShowModal,
	userSelect,
}) => {
	const [multiplyVal, setMultiplyVal] = useState(1);
	const [inputVal, setInputVal] = useState(20);

	const handleBet = () => {
		setShowModal(false);
		// console.log("test", multiplyVal * inputVal, userSelect);
		socketInstance?.emit("reqBet", {
			bettingAmount: multiplyVal * inputVal,
			bettingTag: userSelect,
		});
	};

	const handleClose = () => {
		handleUserSelectValueOnModelClose();
		setShowModal(false);
	};

	const handleMultiplayer = (val) => {
		if (inputVal > 19 && multiplyVal + val > 0) {
			setMultiplyVal((prev) => prev + val);
		}
	};
	return (
		<>
			{showModal ? (
				<>
					<div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
						<div className="relative w-auto md:max-w-3xl md:mx-auto md:my-6">
							<div className="relative p-3 border-0 rounded-lg shadow-lg outline-none md:w-full rounded-t-xl bg-darkslategray focus:outline-none">
								<div className="flex items-start justify-between p-3 border-b border-gray-300 border-solid rounded-t ">
									<div className="w-full">
										<h3 className="text-3xl m-0 font=semibold text-white text-center">
											<div className="">
												<span className="text-lightslategray">Selected :</span>{" "}
												{userSelect}
											</div>
										</h3>
									</div>

									<button
										className="float-right text-black bg-transparent border-0"
										onClick={handleClose}
									>
										<span className="block w-6 h-6 py-0 text-xl text-white bg-gray-400 rounded-full opacity-7">
											x
										</span>
									</button>
								</div>

								<div className="text-lg ">
									<div className=" box-border w-full h-px border-t-[1px] border-solid border-dark-blue" />
									<div className="m-2">
										<div className="inline-block my-2">Contact Money</div>
										<div className="flex flex-row items-center justify-between mt-2 mb-4 text-center text-3xs text-lightslategray">
											<div
												className="rounded-8xs bg-light-blue w-[52px] h-[25px] flex flex-row py-3.5 px-5 box-border items-center justify-center"
												style={{
													border: inputVal === 20 ? "2px solid orange" : "none",
												}}
											>
												<b className="relative" onClick={() => setInputVal(20)}>
													+20
												</b>
											</div>
											<div
												className="rounded-8xs bg-light-blue w-[52px] h-[25px] flex flex-row py-3.5 px-5 box-border items-center justify-center"
												style={{
													border: inputVal === 50 ? "2px solid orange" : "none",
												}}
											>
												<b className="relative" onClick={() => setInputVal(50)}>
													+50
												</b>
											</div>
											<div
												className="rounded-8xs bg-light-blue w-[58px] h-[25px] flex flex-row py-3.5 px-5 box-border items-center justify-center"
												style={{
													border:
														inputVal === 100 ? "2px solid orange" : "none",
												}}
											>
												<b
													className="relative"
													onClick={() => setInputVal(100)}
												>
													+100
												</b>
											</div>
											<div
												className="rounded-8xs bg-light-blue w-[63px] h-[25px] flex flex-row py-3.5 px-5 box-border items-center justify-center"
												style={{
													border:
														inputVal === 1000 ? "2px solid orange" : "none",
												}}
											>
												<b
													className="relative"
													onClick={() => setInputVal(1000)}
												>
													+1000
												</b>
											</div>
											<div
												className="rounded-8xs bg-light-blue w-[63px] h-[25px] flex flex-row py-3.5 px-5 box-border items-center justify-center"
												style={{
													border:
														inputVal === 5000 ? "2px solid orange" : "none",
												}}
											>
												<b
													className="relative"
													onClick={() => setInputVal(5000)}
												>
													+5000
												</b>
											</div>
										</div>
									</div>
									{/* <div className="m-2 flex flex-row items-start justify-start gap-[5px] text-center text-base text-lightslategray">
                    <div className="rounded-8xs bg-darkgray w-full h-[58px] flex flex-row py-3.5 px-5 box-border items-center justify-start">
                      <b className="relative leading-[130.4%]">Rs. 200</b>
                    </div>
                    <div className="rounded-8xs bg-blue-main w-[160px] h-[58px] flex flex-row py-3.5 px-5 box-border items-center justify-center text-black">
                      <b className="relative leading-[130.4%]">BET</b>
                    </div>
                  </div> */}

									<div className="mt-4 mb-2">
										<div className="my-2">Number</div>
										<div className="rounded-8xs bg-light-blue  flex flex-col p-2.5 box-border items-center justify-center text-center text-base text-lightslategray">
											<div className="flex flex-row items-start justify-start gap-[34px]">
												<div className="flex flex-row items-start justify-start gap-[4px]">
													<div className="rounded-8xs bg-darkgray w-[51px] h-[35px] flex flex-row py-3.5 px-5 box-border items-center justify-center">
														<b
															className="relative"
															onClick={() => handleMultiplayer(-5)}
														>
															-5
														</b>
													</div>
													<div className="rounded-8xs bg-darkgray w-[51px] h-[35px] flex flex-row py-3.5 px-5 box-border items-center justify-center">
														<b
															className="relative"
															onClick={() => handleMultiplayer(-1)}
														>
															-1
														</b>
													</div>
												</div>
												<b className="relative text-[26px]">{multiplyVal}</b>
												<div className="flex flex-row items-start justify-start gap-[4px]">
													<div className="rounded-8xs bg-darkgray w-[55px] h-[35px] flex flex-row py-3.5 px-5 box-border items-center justify-center">
														<b
															className="relative"
															onClick={() => handleMultiplayer(1)}
														>
															+1
														</b>
													</div>
													<div className="rounded-8xs bg-darkgray w-[55px] h-[35px] flex flex-row py-3.5 px-5 box-border items-center justify-center">
														<b
															className="relative"
															onClick={() => handleMultiplayer(5)}
														>
															+5
														</b>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="w-full my-4 text-base text-center">
										<span>{`Total Amount :  `}</span>
										<b className="text-5xl">Rs.{multiplyVal * inputVal}</b>
									</div>
									<div
										className="m-2 rounded-3xs bg-crimson  h-[58px] flex flex-row py-3.5 px-5 box-border items-center justify-center text-center text-base"
										onClick={handleBet}
									>
										<b className="relative leading-[130.4%]">Confirm</b>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

export default ParityBetModel;
