import React from "react";
import { useNavigate } from "react-router-dom";
import { checkUserBlockStatus } from "../utils/constant";

const PrimaryButton = ({ text, children, isBlock, setIsBlock, ...rest }) => {
	const navigate = useNavigate();
	// const [isBlock, setIsBlock] = useState(false);
	const isBlocked = checkUserBlockStatus();
	const handleClick = () => {
		if (isBlocked) {
			setIsBlock(true);
		} else {
			if (text === "Recharge") {
				navigate("/recharge");
			}
		}
	};

	return (
		<>
			<button
				{...rest}
				className="rounded-mini bg-light-blue w-36 flex flex-row py-3.5 px-5 box-border items-center justify-center"
				onClick={handleClick}
			>
				<b className="relative text-white">{text || children}</b>
			</button>
		</>
	);
};

export default PrimaryButton;
