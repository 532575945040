// Gameplay scene
import Phaser from "phaser";
import { socketInstance } from "..";

export class GameScene extends Phaser.Scene {
	constructor() {
		super({ key: "GameScene" });
		this.isVisible = true;
		this.timer = 0;
		this.rocketHeight = "1.00";
		this.count = true;
		this.rocketAudio = null;
		this.waitingAnim = null;
		this.progress = 0;
		this.isRocket1Visible = true;
		this.rocket1 = null;
		this.rocket2 = null;
		this.finalHeight = 0;
	}

	create() {
		this.createLine(); // Move the creation of lines to the create function
		this.updateLinesVisibility(true); // Set lines visible initially
		// Create an instance of the audio with loop set to true
		const audio = this.sound.add("gameSound", { loop: true });
		// Play the audio
		audio.play();
		this.resetPostion();

		if (socketInstance) {
			socketInstance?.on("resBettingTime", (data) => {
				this.timer = data.data.bettingTime;
			});
			socketInstance?.on("resGameState", (res) => {
				this.isFirstTime = res.data.isRoketOn;
			});

			socketInstance?.on("resRocketHeight", (data) => {
				// this.rocketHeight = Number(data.data.roketHeight).toFixed(2);
				this.stopWaitingAnimation();
				this.winTxt.text = Number(data.data.roketHeight).toFixed(2) + "x";

				if (this.count) {
					this.count = false;
					this.drawSmokeLine();
					this.isVisible = true;

					this.rocketAudio = this.sound.add("rocketAudio");
					this.rocketAudio.play();
				}
			});
			socketInstance?.on("resRocketBlast", (res) => {
				this.finalHeight = res.data.finalHeight;
				this.winTxt.setVisible(false);
				this.isVisible = false;
				this.count = true;
				this.rocketHeight = "1.00";
				this.destroyShip(this.rocket1, this.rocket2);
			});

			socketInstance?.on("resBettingTimeStart", () => {});

			socketInstance?.on("resBettingTimeEnd", () => {
				this.stopWaitingAnimation();
				this.winTxt.text = this.rocketHeight + "x";
				this.isVisible = true;
			});
		}
	}

	update() {
		// ... (Your existing update code)
		this.updateLinesVisibility(this.isVisible); // Update lines visibility based on the isVisible property
	}

	createLine() {
		this.horizentalLine = this.add.graphics();
		this.horizentalLine.lineStyle(2, 0xffffff);

		// Draw a horizontal line
		const startX = 20;
		const startY = 280;
		const endX = 500;
		const endY = startY;
		this.horizentalLine.moveTo(startX, startY);
		this.horizentalLine.lineTo(endX, endY);
		this.horizentalLine.strokePath();

		// Add glowable dots along the horizontal line
		this.addGlowableDots(
			this.horizentalLine,
			startX + 18,
			startY + 12,
			endX + 12,
			endY + 12,
			30
		);

		this.verticalLine = this.add.graphics();
		this.verticalLine.lineStyle(2, 0xffffff);

		// Draw a vertical line
		const startXv = 20;
		const startYv = 0;
		const endXv = startXv;
		const endYv = 280;
		this.verticalLine.moveTo(startXv, startYv);
		this.verticalLine.lineTo(endXv, endYv);
		this.verticalLine.strokePath();

		// Add glowable dots along the vertical line
		this.addGlowableDots(
			this.verticalLine,
			startXv - 12,
			startYv - 12,
			endXv - 12,
			endYv + 50,
			30
		);
	}

	addGlowableDots(line, startX, startY, endX, endY, spacing) {
		const totalLength = Phaser.Math.Distance.Between(
			startX,
			startY,
			endX,
			endY
		);
		const totalDots = Math.ceil(totalLength / spacing);

		for (let i = 0; i <= totalDots; i++) {
			const t = i / totalDots;
			const x = Phaser.Math.Linear(startX, endX, t);
			const y = Phaser.Math.Linear(startY, endY, t);

			// Draw a glowable dot at (x, y)
			line.fillStyle(0x34b4ff, 0.6); // White color with full opacity
			line.fillCircle(x, y, 2); // Adjust the radius as needed
		}
	}

	drawSmokeLine() {
		const startPoint = new Phaser.Math.Vector2(15, 278);
		const controlPoint = new Phaser.Math.Vector2(250, 250);
		const endPoint = new Phaser.Math.Vector2(400, 50);
		const curve = new Phaser.Curves.QuadraticBezier(
			startPoint,
			controlPoint,
			endPoint
		);

		const points = curve.getPoints(100);

		let i = 1;

		// Create a separate graphics object for the filled shape
		this.filledGraphics = this.add.graphics();
		this.lineAboveFilledGraphics = this.add.graphics(); // New graphics object for the line above filledGraphics
		this.filledGraphics.setDepth(this.background.depth + 1);
		this.lineAboveFilledGraphics.setDepth(this.background.depth + 1);
		// Function to update the filled area below the curve
		const updateFilledArea = () => {
			this.filledGraphics.clear();
			this.filledGraphics.fillStyle(0xff0000, 0.5); // Set fill style
			this.filledGraphics.beginPath();
			this.filledGraphics.moveTo(20, 280); // Start from rocket1 position
			this.lineAboveFilledGraphics.clear();
			this.lineAboveFilledGraphics.lineStyle(3, 0xff0000);
			this.lineAboveFilledGraphics.beginPath();
			this.lineAboveFilledGraphics.moveTo(20, 280); // Start from rocket1 position

			for (let j = 1; j <= i; j++) {
				this.filledGraphics.lineTo(this.rocket1.x - 35, this.rocket1.y + 20); // Use rocket1 position or adjust as needed
				this.lineAboveFilledGraphics.lineTo(
					this.rocket1.x - 35,
					this.rocket1.y + 20
				); // Adjust the position as needed
			}

			this.filledGraphics.lineTo(this.rocket1.x - 35, 278); // Adjust the bottom value based on your game's dimensions
			this.filledGraphics.closePath();
			this.filledGraphics.fillPath();

			this.lineAboveFilledGraphics.strokePath();
		};

		this.time.addEvent({
			delay: 100,
			repeat: points?.length - 2,
			callback: function () {
				if (this.isVisible === false) {
					// Don't need to do anything if not visible
					return;
				}

				if (i >= 0 && i < points?.length) {
					if (points[i].x >= 400 && points[i].y <= 100) {
						this.tweens.add({
							targets: [this.rocket1, this.rocket2],
							x: points[i].x - 60,
							y: points[i].y - 35,
							duration: 3000,
							ease: "Linear",
							yoyo: true,
							repeat: -1,
							onUpdate: updateFilledArea,
							callbackScope: this,
							onComplete: function () {},
						});
					} else {
						this.tweens.add({
							targets: [this.rocket1, this.rocket2],
							x: points[i].x + 60,
							y: points[i].y - 28,
							duration: 500,
							ease: "circular",
							onUpdate: updateFilledArea,
							callbackScope: this,
						});
					}

					i++;
				}
			},
			callbackScope: this,
		});
	}

	// Add this function to toggle between rocket1 and rocket2 every second
	toggleRockets() {
		this.time.addEvent({
			delay: 150, // Toggle every second
			repeat: -1, // Repeat indefinitely
			callback: function () {
				if (this.isRocket1Visible) {
					this.rocket1.visible = true;
					this.rocket2.visible = false;
				} else {
					this.rocket1.visible = false;
					this.rocket2.visible = true;
				}
				this.isRocket1Visible = !this.isRocket1Visible;
			},
			callbackScope: this,
		});
	}

	destroyShip(gameObject1, gameObject2) {
		if (gameObject1 === this.rocket1 || gameObject2 === this.rocket2) {
			// Remove the graphics and this.filledGraphics objects
			if (this.lineAboveFilledGraphics) {
				this.lineAboveFilledGraphics.destroy();
			}
			if (this.filledGraphics) {
				this.filledGraphics.destroy();
			}
			this.blastTxt.text = Number(this.finalHeight).toFixed(2) + " x";
			this.flyAwayText.text = "FLEW AWAY!";
			this.flyAwayText.setVisible(true);
			this.blastTxt.setVisible(true);
			// Create a new rocket animation
			this.tweens.add({
				targets: [this.rocket1, this.rocket2],
				x: 600, // Exit the canvas to the right
				y: 0, // Move vertically upwards
				duration: 1000, // Adjust the duration as needed
				onComplete: () => {
					this.startWaitingAnimation();
					if (this.explosionAudio) {
						this.explosionAudio.stop();
					}
					// Reset position after the exit animation is complete
					this.resetPostion();
				},
			});

			// Play the explosion audio
			const explosionAudio = this.sound.add("blastAudio");
			explosionAudio.play();
			if (this.explosionAudio && this.explosionAudio.isPlaying) {
				this.explosionAudio.stop();
			}
			// Stop the rocketAudio if it's playing
			if (this.rocketAudio && this.rocketAudio.isPlaying) {
				this.rocketAudio.stop();
			}
		}
	}

	// In the resetPosition function
	resetPostion() {
		this.flyAwayText?.setVisible(false);
		this.blastTxt?.setVisible(false);
		this.toggleRockets();

		this.rocket1?.destroy();

		this.rocket2?.destroy();

		this.winTxt?.destroy();

		this.blastTxt?.destroy();

		this.flyAwayText?.destroy();

		this.waitingText?.destroy();

		this.isVisible = false;

		if (!this.background) {
			this.background = this.add
				.sprite(0, 320, "background")
				.setOrigin(0.5)
				.setScale(0.25, 0.4);
		}

		// Define the rotation animation
		this.backgroundAnim = this.tweens.add({
			targets: this.background,
			angle: 360, // Rotate 360 degrees (one full circle)
			duration: 20000, // Duration in milliseconds
			repeat: -1, // Repeat indefinitely
			ease: "Linear",
		});
		// Change the opacity of the image
		this.background.setAlpha(0.2); // Set the opacity to a value between 0 (completely transparent) and 1 (completely opaque)

		// this.backgroundAnim.play(); // Start the animation

		this.rocket1 = this.add.sprite(70, 260, "player1"); // Change to sprite object
		this.rocket2 = this.add.sprite(70, 260, "player2"); // Change to sprite object
		this.rocket1.setScale(0.7);
		this.rocket1.rotation = 0;
		this.rocketHeight = "1.00";
		this.rocket2.setScale(0.7);
		this.rocket2.rotation = 0;

		this.winTxt = this.add.text(260, 150, "", {
			fontSize: "5rem",
			fontFamily: "aviator-bolt-font",
			color: "#fff",
		});

		// Set the depth dynamically based on the depth of background
		this.winTxt.setDepth(1000);

		this.winTxt.setOrigin(0.5);

		if (!this.glowImg) {
			// Create different instances of the SVG image with different colors
			this.glowImg = this.add
				.image(258, 140, "glow")
				.setOrigin(0.5)
				.setScale(0.7);
		}

		this.glowImg.setDepth(this.background.depth + 2);

		this.glowImg.alpha = 0.6;

		// Define colors and alpha values
		const colors = [0x34b4ff, 0x913ef8, 0xc017b4];
		let currentColorIndex = 0;
		let targetAlpha = 0.3;
		// Animation configuration
		// Animation configuration
		const animationConfig = {
			targets: this.glowImg,
			duration: 1500, // in milliseconds
			alpha: { value: targetAlpha, yoyo: true, repeat: -1 },
			onYoyo: () => {
				// Change color after completing one animation cycle
				currentColorIndex = (currentColorIndex + 1) % colors.length;
				this.glowImg.tint = colors[currentColorIndex];

				// Toggle alpha value
				targetAlpha = targetAlpha === 0.2 ? 0.6 : 0.2;
			},
		};

		// Create the animation
		this.tweens.add(animationConfig);

		this.blastTxt = this.add.text(260, 150, "", {
			fontSize: "5rem",
			fontFamily: "aviator-bolt-font",
			color: "#FF0000",
			fontWeight: 900,
		});

		this.blastTxt.setOrigin(0.5);

		this.flyAwayText = this.add.text(170, 40, "", {
			fontSize: "1.7rem",
			fontFamily: "aviator-regular-font",
			color: "#fff",
		});

		// Set the depth dynamically based on the depth of background
		this.flyAwayText.setDepth(1000);

		this.waitingText = this.add.text(250, 170, "WAITING FOR NEXT ROUND", {
			fontSize: "1.7rem",
			fontFamily: "aviator-regular-font",
			color: "#fff",
		});

		// Set the depth dynamically based on the depth of background
		this.waitingText.setDepth(1000);

		this.waitingText.setOrigin(0.5);
		if (!this.waitAnimation) {
			this.waitAnimation = this.add
				.sprite(238, 120, "waitAnim")
				.setOrigin(0.5)
				.setScale(0.5);
		}

		// Define the rotation animation
		this.waitingAnim = this.tweens.add({
			targets: this.waitAnimation,
			angle: 360,
			duration: 1500,
			repeat: -1,
			ease: "Linear",
		});
	}

	startWaitingAnimation() {
		this.waitingText.setVisible(true);
		this.winTxt.setVisible(false);
		this.waitAnimation.setVisible(true);
		this.waitingAnim.play(); // Start the animation
		this.backgroundAnim.pause(); // Pause the animation

		// Check if the loading bar is already created
		if (!this.progressBar) {
			this.createLoadingBar();
		}
	}

	stopWaitingAnimation() {
		this.waitingText.setVisible(false);
		this.winTxt.setVisible(true);
		this.waitAnimation.setVisible(false);
		this.waitingAnim.pause(); // Pause the animation
		this.backgroundAnim.play(); // Pause the animation

		// Check if the loading bar exists and destroy it
		if (this.progressBar) {
			this.progressBar.destroy();
			this.progressBar = null; // Reset the reference to null
		}
	}

	createLoadingBar() {
		const width = this.cameras.main.width;
		const height = this.cameras.main.height;
		const progressBarWidth = width / 4; // Adjust as needed
		const duration = 5000; // Animation duration in milliseconds

		if (!this.progressBar) {
			this.progressBar = this.add.graphics();
		}

		// Adjust these coordinates to change the position of the progress bar
		// const progressBarX = (width - progressBarWidth) / 2;
		// const progressBarY = height / 2;

		const progressBarX = 185;
		const progressBarY = 200;

		// Draw the initial white border with rounded corners
		const border = new Phaser.Geom.Rectangle(
			progressBarX - 2,
			progressBarY - 4,
			progressBarWidth + 4,
			8
		);
		this.progressBar.fillStyle(0xffffff, 1);
		this.progressBar.fillRoundedRect(
			border.x,
			border.y,
			border.width,
			border.height,
			4
		);

		// Draw the initial red progress bar inside the white border
		const bar = new Phaser.Geom.Rectangle(
			progressBarX,
			progressBarY - 2,
			0, // Initial width set to 0
			4
		);

		// Fill the progress bar with red color
		this.progressBar.fillStyle(0xff0000, 1);
		this.progressBar.fillRect(bar.x, bar.y, bar.width, bar.height);

		// Set the initial opacity of the entire progress bar
		this.progressBar.setAlpha(0.9); // Adjust the opacity as needed

		// Animate the bar width to the full width inside the border
		this.tweens.add({
			targets: bar,
			width: progressBarWidth,
			duration: duration,
			repeat: -1,
			onUpdate: (tween, target) => {
				if (this.progressBar) {
					this.progressBar.clear();

					// Draw the white border with rounded corners
					this.progressBar.fillStyle(0xffffff, 1);
					this.progressBar.fillRoundedRect(
						border.x,
						border.y,
						border.width,
						border.height,
						4
					);

					// Draw the red progress bar inside the white border
					this.progressBar.fillStyle(0xff0000, 1);
					this.progressBar.fillRect(bar.x, bar.y, target.width, bar.height);
				}
			},
			onComplete: () => {
				if (this.progressBar) {
					this.progressBar.destroy();
				}
			},
		});
	}

	updateLinesVisibility(isVisible) {
		// Update the visibility of the lines based on the isVisible property
		this.horizentalLine.setVisible(isVisible);
		this.verticalLine.setVisible(isVisible);
		this.glowImg?.setVisible(isVisible);
	}
}
