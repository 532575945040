import React, { useEffect, useRef, useState } from "react";
import ParityBetModel from "./Paritybet";
// import BetModel from "../andar-bahar/bet-model";
// import { socketInstance } from "../andar-bahar/cards";
// import GreenModal from "./GreenModal";
import { io } from "socket.io-client";
// import { SOCKET_URL } from "../../utils/constant";
import AnadarBaharResultModal from "../andar-bahar/result-model";
import AndarBaharRecords from "../andar-bahar/records";
// import { nanoid } from "nanoid";
import RechargeModal from "../../shared/recharge-modal";
import {
	SOCKET_URL,
	getToken,
	getUserBalance,
	notifyError,
	removeLocalStorage,
} from "../../utils/constant";
import { Navigate, useNavigate } from "react-router-dom";

import ParityHistory from "./ParityHistory";
import ParityResultModal from "./ParityResult";
import ParityMsgModel from "./ParityMsgModel";
import { getDashboardData } from "../../services/backendServices";

export let socketInstance = null;

const ParityDesign = () => {
	let confirm = useRef();
	//show result model
	const [timer, setTimer] = useState(false);
	const [bettingTime, setBettingTime] = useState();
	const [betAmount, setBetAmount] = useState(0);
	const [userSelect, setUserSelect] = useState();
	const [showModal, setShowModal] = useState(false);
	const [showResultModal, setResultShowModal] = useState(false);
	const [showMsgModal, setMsgModal] = useState(false);
	const [gameResult, setGameResult] = useState(null);
	const [rechargeModal, setRechargeModal] = useState(false);
	const [totalBalance, setTotalBalance] = useState();
	const [records, setRecords] = useState([]);
	const [isBetted, setIsBetted] = useState(false);
	const [msg, setMsg] = useState("");
	const [historyData1, setHistoryData1] = useState([]);
	const [historyData2, setHistoryData2] = useState([]);
	const [historyData3, setHistoryData3] = useState([]);
	// everyone's order
	const [buffer, setBuffer] = useState([]);
	const bufferSize = 6;

	const addToBuffer = (newValue) => {
		setBuffer((prevBuffer) =>
			prevBuffer.length >= bufferSize
				? [...prevBuffer.slice(1), newValue]
				: [...prevBuffer, newValue]
		);
	};

	const handleUserSelect = (val) => {
		if (timer) {
			setUserSelect(val);
			setShowModal(true);
		}
	};

	const handleUserSelectValueOnModelClose = () => {
		setUserSelect("");
	};

	const navigate = useNavigate();

	const fetchData = async () => {
		try {
			const res = await getDashboardData();
			if (res.status) {
				setTotalBalance(Number(res.user_balance));
			} else {
				notifyError(res.msg);
			}
		} catch (error) {
			console.log("error in fetch dashbordata", error);
		}
	};

	useEffect(() => {
		const token = getToken();
		const IP = SOCKET_URL;

		// Create a new WebSocket connection
		socketInstance = io(IP, {
			transports: ["websocket", "polling"],
			query: {
				game: "parity",
				authorization: token,
			},
		});

		socketInstance?.on("connect", () => {
			// console.log("parity WebSocket connected");
		});

		socketInstance?.on("remove_user", (msg) => {
			removeLocalStorage();
			setTimeout(() => {
				navigate("/login");
			}, 500);
		});

		socketInstance?.on("resGameState", (res) => {
			// console.log("resGameState : ", res);
			const data = res.data.results_history.slice(0, 6); // Keep only the last 6 items
			setBuffer(data);
			setHistoryData1(res.data.last_fifteen_history.slice(0, 10));
			setHistoryData2(res.data.last_fifteen_history.slice(10, 20));
			setHistoryData3(res.data.last_fifteen_history.slice(20, 30));
			handleTimer(res.data.isBettingOn);
		});

		socketInstance?.on("resBettingTimeStart", () => {
			// console.log("start Time");
			handleTimer(true);
		});

		socketInstance?.on("resBettingTime", (res) => {
			// console.log("resBettingTime : ", res);
			setIsBetted(false);
			setBettingTime(res.data);
		});

		socketInstance?.on("resBettingTimeEnd", (res) => {
			handleTimer(false);
		});

		socketInstance?.on("resBet", (res) => {
			// console.log("res bet", res);
			if (res.data.msg === "Insufficient balance") {
				setRechargeModal(true);
			} else {
				if (res.data.status) {
					setBetAmount(res.data.amount);
					handleTimer(false);
					setTotalBalance((prev) => prev - Number(res.data.amount));
				} else {
					handleUserSelectValueOnModelClose();
					setMsg(res.data.msg);
					setTimeout(() => {
						setMsgModal(true);
					}, 100);
				}
			}
		});

		socketInstance?.on("resResult", (data) => {
			// console.log('resResult : ', data.data.amount)
			setIsBetted(true);
			setResultShowModal(true);
			setGameResult(data.data.amount);
			if (data.data.amount > 0) {
				fetchData();
			}
			setUserSelect("");
			setBetAmount(0);
		});

		socketInstance?.on("parity_results", (res) => {
			addToBuffer(res);
		});
		socketInstance?.on("parity_latest_records", (res) => {
			// console.log('resGameState : ', res.data.isBettingOn)
			setHistoryData1(res.slice(0, 10));
			setHistoryData2(res.slice(10, 20));
			setHistoryData3(res.slice(20, 30));
		});
		const balance = getUserBalance();
		setTotalBalance(Number(balance));
		socketInstance?.on("disconnect", () => {
			// console.log("PARITY WebSocket disconnected");
		});
		fetchData();
		// Don't forget to close the socket when the component unmounts
		return () => {
			socketInstance.disconnect();
		};
	}, []);

	const handleTimer = (val) => {
		setTimer(val);
	};

	const formatTime = (time) => {
		if (time !== 1 && time) {
			// const remainingSeconds = time % 20
			return `00:${time?.toString()?.padStart(2, "0")}`;
		} else {
			return "00:00";
		}
	};

	return (
		// top part starts here
		<div>
			<div id="top" className="flex justify-between mx-2">
				<div>
					<p className="text-[16px] mt-5 mb-0">Betted</p>
					<p className="mt-1">₹ {betAmount}</p>
				</div>

				<div className="mb-2">
					<p className="text-[16px] flex justify-center mt-5 mb-1">
						Count Down
					</p>
					<div className="flex justify-center">
						{formatTime(bettingTime?.bettingTime)}
					</div>
				</div>
			</div>
			{/* color buttons starts here... */}
			<div className="flex items-center justify-center my-4 w-full md:w-96">
				<div className="flex w-full mx-2 md:w-[103.46px] flex-col items-center justify-center flex-1">
					<div
						className="rounded-mini bg-green-700 w-full justify-center md:w-[103.46px] flex flex-row py-3.5 px-1 box-border items-center text-[17px] text-white"
						onClick={() => handleUserSelect("green")}
						style={{
							opacity:
								!timer && userSelect !== "green" && !isBetted ? "50%" : "100%",
						}}
					>
						Join Green
					</div>
					<div className="my-2 text-sm text-center">1:2</div>
				</div>
				<div className="flex w-full mx-2 md:w-[103.46px] flex-col items-center justify-center flex-1">
					<button
						className=" rounded-mini bg-violet-700 w-full md:w-[103.46px] flex flex-col py-3.5  box-border items-center  text-[17px] text-white px-2"
						onClick={() => handleUserSelect("violet")}
						style={{
							opacity:
								!timer && userSelect !== "violet" && !isBetted ? "50%" : "100%",
						}}
					>
						Join Violet
					</button>
					<div className="my-2 text-sm text-center">1:9</div>
				</div>
				<div className="flex w-full mx-2 md:w-[103.46px] flex-col items-center justify-center flex-1">
					<button
						className={`rounded-mini bg-red-700 justify-center  w-full md:w-[103.46px] flex flex-row py-3.5 box-border items-center  text-[17px] text-white`}
						onClick={() => handleUserSelect("red")}
						style={{
							opacity:
								!timer && userSelect !== "red" && !isBetted ? "50%" : "100%",
						}}
					>
						Join Red
					</button>
					<div className="my-2 text-sm text-center">1:2</div>
				</div>
			</div>
			<div className="flex flex-row items-center justify-center mb-2 text-sm text-white">
				Total Balance : {totalBalance?.toFixed(2)}
			</div>

			{/* numbrer-buttons starts here */}

			<div className="relative p-2 text-base text-center rounded-lg text-lightslategray font-fs-elliot-pro bg-darkslategray mt-3">
				<div className="flex justify-between mt-2">
					<button
						className={`w-full m-2 h-9 bg-[#05071E] border-[1px] border-solid border-white rounded-lg text-white text-[17px]`}
						onClick={() => handleUserSelect("0")}
						style={{
							opacity:
								!timer && userSelect !== "0" && !isBetted ? "50%" : "100%",
						}}
					>
						0
					</button>
					<button
						className={`w-full m-2 h-9 bg-[#05071E] border-[1px] border-solid border-white rounded-lg text-white text-[17px]`}
						onClick={() => handleUserSelect("1")}
						style={{
							opacity:
								!timer && userSelect !== "1" && !isBetted ? "50%" : "100%",
						}}
					>
						1
					</button>
					<button
						className={`w-full m-2 h-9 bg-[#05071E] border-[1px] border-solid border-white rounded-lg text-white text-[17px]`}
						onClick={() => handleUserSelect("2")}
						style={{
							opacity:
								!timer && userSelect !== "2" && !isBetted ? "50%" : "100%",
						}}
					>
						2
					</button>
					<button
						className={`w-full m-2 h-9 bg-[#05071E] border-[1px] border-solid border-white rounded-lg text-white text-[17px]`}
						onClick={() => handleUserSelect("3")}
						style={{
							opacity:
								!timer && userSelect !== "3" && !isBetted ? "50%" : "100%",
						}}
					>
						3
					</button>
					<button
						className={`w-full m-2 h-9 bg-[#05071E] border-[1px] border-solid border-white rounded-lg text-white text-[17px]`}
						onClick={() => handleUserSelect("4")}
						style={{
							opacity:
								!timer && userSelect !== "4" && !isBetted ? "50%" : "100%",
						}}
					>
						4
					</button>
				</div>
				<div className="flex justify-between mb-2">
					<button
						className={`w-full m-2 h-9 bg-[#05071E] border-[1px] border-solid border-white rounded-lg text-white text-[17px]`}
						onClick={() => handleUserSelect("5")}
						style={{
							opacity:
								!timer && userSelect !== "5" && !isBetted ? "50%" : "100%",
						}}
					>
						5
					</button>
					<button
						className={`w-full m-2 h-9 bg-[#05071E] border-[1px] border-solid border-white rounded-lg text-white text-[17px]`}
						onClick={() => handleUserSelect("6")}
						style={{
							opacity:
								!timer && userSelect !== "6" && !isBetted ? "50%" : "100%",
						}}
					>
						6
					</button>
					<button
						className={`w-full m-2 h-9 bg-[#05071E] border-[1px] border-solid border-white rounded-lg text-white text-[17px]`}
						onClick={() => handleUserSelect("7")}
						style={{
							opacity:
								!timer && userSelect !== "7" && !isBetted ? "50%" : "100%",
						}}
					>
						7
					</button>
					<button
						className={`w-full m-2 h-9 bg-[#05071E]  border-[1px] border-solid border-white rounded-lg text-white text-[17px]`}
						onClick={() => handleUserSelect("8")}
						style={{
							opacity:
								!timer && userSelect !== "8" && !isBetted ? "50%" : "100%",
						}}
					>
						8
					</button>
					<button
						className={`w-full m-2 h-9 bg-[#05071E]  border-[1px] border-solid border-white rounded-lg text-white text-[17px]`}
						onClick={() => handleUserSelect("9")}
						style={{
							opacity:
								!timer && userSelect !== "9" && !isBetted ? "50%" : "100%",
						}}
					>
						9
					</button>
				</div>
			</div>

			{/* Record starts here.. */}

			<div className="mt-2 mb-4">
				<div className="flex justify-center text-lg ">Parity Record</div>
				<div className="relative p-2 text-base text-center rounded-lg font-fs-elliot-pro bg-darkslategray mt-3 overflow-hidden">
					<div className="mt-3 flex flex-wrap justify-around">
						{historyData1?.map((num, index) => (
							<>
								<span
									key={index}
									className={`${
										num == 1 || num == 3 || num == 7 || num == 9
											? "bg-green-700"
											: num == 2 || num == 4 || num == 6 || num == 8
											? "bg-red-700"
											: ""
									} w-7 rounded-xl h-7 mr-1 mb-2 flex items-center justify-center text-white `}
									style={{
										background:
											num == 5
												? "linear-gradient(to right, green 50%, rgb(109 40 217) 50%)"
												: num == 0
												? "linear-gradient(to right, rgb(185 28 28) 50%, rgb(109 40 217) 50%)"
												: "",
									}}
								>
									{num}
								</span>
							</>
						))}
					</div>
					<div className="mt-3 flex flex-wrap justify-around">
						{historyData2?.map((num, index) => (
							<>
								<span
									key={index}
									className={`${
										num == 1 || num == 3 || num == 7 || num == 9
											? "bg-green-700"
											: num == 2 || num == 4 || num == 6 || num == 8
											? "bg-red-700"
											: ""
									} w-7 rounded-xl h-7 mr-1 mb-2 flex items-center justify-center text-white `}
									style={{
										background:
											num == 5
												? "linear-gradient(to right, green 50%, rgb(109 40 217) 50%)"
												: num == 0
												? "linear-gradient(to right, rgb(185 28 28) 50%, rgb(109 40 217) 50%)"
												: "",
									}}
								>
									{num}
								</span>
							</>
						))}
					</div>
					<div className="mt-3 flex flex-wrap justify-around">
						{historyData3?.map((num, index) => (
							<>
								<span
									key={index}
									className={`${
										num == 1 || num == 3 || num == 7 || num == 9
											? "bg-green-700"
											: num == 2 || num == 4 || num == 6 || num == 8
											? "bg-red-700"
											: ""
									} w-7 rounded-xl h-7 mr-1 mb-2 flex items-center justify-center text-white `}
									style={{
										background:
											num == 5
												? "linear-gradient(to right, green 50%, rgb(109 40 217) 50%)"
												: num == 0
												? "linear-gradient(to right, rgb(185 28 28) 50%, rgb(109 40 217) 50%)"
												: "",
									}}
								>
									{num}
								</span>
							</>
						))}
					</div>
				</div>
			</div>
			<ParityResultModal
				showModal={showResultModal}
				setShowModal={setResultShowModal}
				gameResult={gameResult}
				setGameResult={setResultShowModal}
			/>
			<ParityHistory buffer={buffer} />

			<ParityMsgModel
				showModal={showMsgModal}
				setShowModal={setMsgModal}
				msg={msg}
			/>

			<ParityBetModel
				handleUserSelectValueOnModelClose={handleUserSelectValueOnModelClose}
				showModal={showModal}
				setShowModal={setShowModal}
				userSelect={userSelect}
				setTimer={setTimer}
			/>

			<RechargeModal
				rechargeModal={rechargeModal}
				setRechargeModal={setRechargeModal}
			/>
		</div>
	);
};

export default ParityDesign;
