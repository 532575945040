import { useEffect, useRef, useState } from "react";
import SubHeader from "../shared/sub-header";
import { io } from "socket.io-client";
// import { SOCKET_URL } from "../utils/constant";
import ResultModal from "../components/hilo/result-model";
import Tabs from "../shared/tabs";
import { HiloMyOrders } from "../components/hilo/hilo-history";
import React from "react";
import {
	SOCKET_URL,
	getToken,
	getUserBalance,
	notifyError,
	removeLocalStorage,
} from "../utils/constant";
import RechargeModal from "../shared/recharge-modal";
import GameLayout from "../shared/game-layout";
import Sound from "react-sound";
import { Navigate, useNavigate } from "react-router-dom";

import { getDashboardData } from "../services/backendServices";

const HiloPage = () => {
	const socketObj = useRef();
	// user selected side fro history
	let userSelect = useRef();
	const [showModal, setShowModal] = useState(false);

	//bet value
	const [betValue, setBetValue] = useState(20);
	const [currValue, setCureeValue] = useState(20);
	const [rechargeModal, setRechargeModal] = useState(false);

	//selected amount button border color
	const [selectedValue, setSelectedValue] = useState(20);

	//webscoket event state
	const [cardDetails, setCardDetails] = useState(null);
	const [allCard, setAllCard] = useState([]);
	const [gameResulte, setGameResult] = useState(null);
	const [gameOn, setGameOn] = useState(false);
	const [cardAmount, setcardAmount] = useState();
	// everyone's order
	const [buffer, setBuffer] = useState([]);
	const bufferSize = 6;

	const addToBuffer = (newValue) => {
		if (buffer?.length >= bufferSize) {
			setBuffer((prevBuffer) => [...prevBuffer.slice(1), newValue]);
		} else {
			setBuffer((prevBuffer) => [...prevBuffer, newValue]);
		}
	};

	const navigate = useNavigate();

	const [totalBalance, setTotalBalance] = useState();

	const fetchData = async () => {
		try {
			const res = await getDashboardData();
			if (res.status) {
				setTotalBalance(Number(res.user_balance));
			} else {
				notifyError(res.msg);
			}
		} catch (error) {
			console.log("error in fetch dashbordata", error);
		}
	};

	useEffect(() => {
		const IP = SOCKET_URL;
		const token = getToken();
		// Create a new WebSocket connection
		socketObj.current = io(IP, {
			transports: ["websocket", "polling"],
			query: {
				game: "hilo",
				authorization: token,
			},
		});

		socketObj.current.on("connect", () => {
			// console.log("Hilo WebSocket connected");
		});

		socketObj.current.on("disconnect", () => {
			// console.log("Hilo WebSocket disconnected");
		});

		socketObj.current.on("resInitCard", (res) => {
			// console.log("resInitCard", res.data);
			setTotalBalance((prevBalance) => prevBalance - Number(betValue));
			playSound();
			setGameOn(true);
			setCardDetails(res.data);
			setcardAmount(res.data.amount);
			setAllCard([
				{
					card: res.data.card.val,
					type: res.data.card.type,
					side: null,
				},
			]);
		});

		socketObj.current.on("resCard", (res) => {
			// console.log("resCard", res.data);
			playSound();
			setCardDetails(res.data);
			setAllCard((prev) => [
				...prev,
				{
					card: res.data.card.val,
					type: res.data.card.type,
					side: userSelect.current,
				},
			]);
		});

		socketObj.current.on("resGameResult", (res) => {
			if (res.data.amount > 0) {
				fetchData();
			}
			setGameResult(res.data);
			setShowModal(true);
			setcardAmount();
		});

		socketObj.current.on("remove_user", () => {
			removeLocalStorage();
			setTimeout(() => {
				navigate("/login");
			}, 500);
		});

		socketObj.current.on("hilo_results", (res) => {
			// console.log("hilo_results", res.data);
			addToBuffer(res);
		});

		socketObj.current.on("resStart", (res) => {
			// console.log("resStart", res.data);
			if (res.data.msg === "Insufficient balance") {
				// setTotalBalance((prevBalance) => prevBalance + Number(betValue));
				setRechargeModal(true);
			}
		});

		socketObj.current.on("resCardResult", (res) => {
			setcardAmount(res.data.amount);
		});

		// Don't forget to close the socket when the component unmounts
		return () => {
			socketObj.current.disconnect();
		};
	}, [userSelect]);

	// handle the bet value
	const handleBetValue = (value) => {
		setCureeValue(value);
		setSelectedValue(value);
		setBetValue((prevVal) => prevVal + value);
	};

	// handle minus button
	const handleDecrementValue = () => {
		if (betValue - currValue >= 20) {
			setBetValue((prevVal) => prevVal - currValue);
		} else {
			setBetValue(20);
		}
	};

	// handle game start event and gameOn state
	const handleStart = () => {
		// console.log("started", socketObj);
		if (betValue > 19) {
			socketObj.current.emit("reqStart", {
				bettingAmount: betValue,
			});
		} else {
			setBetValue(20);
		}
	};

	// handle game stop event
	const handleStop = () => {
		socketObj.current.emit("reqStop", {});
	};

	// handle high bet event
	const handleHigh = () => {
		if (gameOn) {
			playSound();
			userSelect.current = "up";
			socketObj.current.emit("reqBet", { bettingSide: "up" });
		}
	};

	// handle low bet event
	const handleLow = () => {
		if (gameOn) {
			playSound();
			userSelect.current = "down";
			socketObj.current.emit("reqBet", { bettingSide: "down" });
		}
	};

	const handleChange = (event) => {
		const newValue = parseInt(event.target.value);
		setBetValue(newValue);
	};

	if (buffer?.length > bufferSize) {
		setBuffer((prevBuffer) => [...prevBuffer.slice(1)]);
	}

	const [soundStatus, setSoundStatus] = useState(Sound.status.STOPPED);

	// Function to play the sound
	const playSound = () => {
		if (soundStatus !== Sound.status.PLAYING) {
			setSoundStatus(Sound.status.PLAYING);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<GameLayout>
				<>
					<Sound
						url="./assets/cards/cardAudio.wav"
						playStatus={soundStatus}
						onFinishedPlaying={() => setSoundStatus(Sound.status.STOPPED)}
					/>
					<SubHeader subTitle="Hilo" showRule />
					<div className="overflow-y-auto flex flex-col md:flex-row items-start justify-center w-full gap-[40px] mb-10">
						<div className="relative my-4 mx-auto md:h-[calc(100vh_-_142px)]">
							<div className="flex items-center justify-center ">
								<div className="flex my-4 text-center w-[100%] font-fs-elliot-pro">
									{gameOn ? (
										<div className="flex items-center justify-center flex-1">
											<div className="relative w-[103.46px] h-[138.56px] text-left text-3xl">
												<img
													src={
														cardDetails.card.type === "h"
															? "./assets/cards/dil.svg"
															: cardDetails.card.type === "d"
																? "./assets/cards/charkat.svg"
																: cardDetails.card.type === "s"
																	? "./assets/cards/kali.svg"
																	: "./assets/cards/fali.svg"
													}
													alt="cards"
													width="100%"
													height="100%"
												/>
												<b
													className={`absolute top-[55.35px] ${cardDetails.card.val == 1 &&
														"left-[calc(50%_-_8.47px)]"
														}

${cardDetails.card.val < 10 &&
														cardDetails.card.val > 1 &&
														"left-[calc(50%_-_6.47px)]"
														}
                          ${cardDetails.card.val == 10 &&
														"left-[calc(50%_-_12.47px)]"
														}
                          ${cardDetails.card.val == 11 &&
														"left-[calc(50%_-_4.47px)]"
														} ${cardDetails.card.val == 12 &&
														"left-[calc(50%_-_9.47px)]"
														} 
${cardDetails.card.val == 13 && "left-[calc(50%_-_7.47px)]"} `}
												>
													{cardDetails.card.val == 11
														? "J"
														: cardDetails.card.val == 12
															? "Q"
															: cardDetails.card.val == 13
																? "K"
																: cardDetails.card.val == 1
																	? "A"
																	: cardDetails.card.val}
												</b>
											</div>
										</div>
									) : (
										<div className="flex justify-center items-center flex-1">
											<div className="relative">
												<div className="rounded-[9.24px] bg-blue-main flex justify-center items-center box-border w-[100px] md:w-[107.15px] h-[142.25px] border-[1.8px] border-solid border-white">
													HILO
												</div>
											</div>
										</div>
									)}
									<div className="flex flex-col items-center justify-center flex-1 gap-4">
										<div
											className="rounded-mini box-border w-[120px] flex flex-row py-3.5 px-5 items-center justify-center border-[1px] border-solid border-white"
											onClick={handleHigh}
										>
											<b className="relative text-base">
												<p className="m-0">High</p>
												<p className="m-0">
													{gameOn
														? cardDetails.rewardval?.high?.toFixed(2)
														: "0.00"}
													X
												</p>
											</b>
										</div>
										<div
											className="rounded-mini box-border w-[120px] flex flex-row py-3.5 px-5 items-center justify-center border-[1px] border-solid border-white"
											onClick={handleLow}
										>
											<b className="relative text-base">
												<p className="m-0">Low</p>
												<p className="m-0">
													{gameOn
														? cardDetails.rewardval?.low?.toFixed(2)
														: "0.00"}
													X
												</p>
											</b>
										</div>
									</div>
								</div>
							</div>
							<div className="relative p-2 text-base text-center rounded-lg text-lightslategray font-fs-elliot-pro bg-darkslategray">
								<div className="flex flex-row items-start justify-start mb-1 text-white">
									Total Balance : {totalBalance?.toFixed(2)}
								</div>
								<div className=" flex flex-row items-start justify-start gap-[5px]">
									<input
										className="rounded-8xs bg-gray w-full h-[58px] flex flex-row py-3.5 px-5 box-border items-center justify-start"
										type="number"
										value={betValue}
										onChange={handleChange}
									/>
									{gameOn ? (
										<div
											className="rounded-8xs bg-orange-100 w-[150px] h-[58px] flex flex-col py-3.5 px-5 box-border items-center justify-center text-white"
											onClick={handleStop}
										>
											<b className="relative leading-[130.4%]">WITHDRAW</b>
											<b>
												Rs.{" "}
												{cardAmount &&
													(cardAmount - cardAmount * 0.05)?.toFixed(2)}
											</b>
										</div>
									) : (
										<div
											className="rounded-8xs bg-orange-100 w-[150px] h-[58px] flex flex-row py-3.5 px-5 box-border items-center justify-center text-white"
											onClick={handleStart}
										>
											<b className="relative leading-[130.4%]">BET</b>
										</div>
									)}
								</div>
								<div className="my-3 flex flex-row items-center justify-center gap-[8px] text-3xs">
									<div
										className="rounded-8xs bg-light-blue  h-[25px] flex flex-row py-3.5 px-5 box-border items-center justify-center"
										style={{
											border:
												selectedValue === 20 ? "2px solid orange" : "none", // If value is 20, set border to 2px solid orange; otherwise, no border
										}}
									>
										<b className="relative" onClick={() => handleBetValue(20)}>
											+20
										</b>
									</div>
									<div
										className="rounded-8xs bg-light-blue  h-[25px] flex flex-row py-3.5 px-5 box-border items-center justify-center"
										style={{
											border:
												selectedValue === 50 ? "2px solid orange" : "none",
										}}
									>
										<b className="relative" onClick={() => handleBetValue(50)}>
											+50
										</b>
									</div>
									<div
										className="rounded-8xs bg-light-blue  h-[25px] flex flex-row py-3.5 px-5 box-border items-center justify-center"
										style={{
											border:
												selectedValue === 100 ? "2px solid orange" : "none",
										}}
									>
										<b className="relative" onClick={() => handleBetValue(100)}>
											+100
										</b>
									</div>
									<div
										className="rounded-8xs bg-light-blue  h-[25px] flex flex-row py-3.5 px-5 box-border items-center justify-center"
										style={{
											border:
												selectedValue === 1000 ? "2px solid orange" : "none",
										}}
									>
										<b
											className="relative"
											onClick={() => handleBetValue(1000)}
										>
											+1000
										</b>
									</div>
									<div
										className="rounded-8xs bg-light-blue  h-[25px] flex flex-row py-3.5 px-5 box-border items-center justify-center"
										style={{
											border:
												selectedValue === 5000 ? "2px solid orange" : "none",
										}}
									>
										<b
											className="relative"
											onClick={() => handleBetValue(5000)}
										>
											+5000
										</b>
									</div>
								</div>
								<div className="my-1 font-extrabold text-center text-5xs">
									WIN-YAR HILO
								</div>
								<div className="flex items-center justify-between">
									<div onClick={handleDecrementValue}>
										<img
											className="w-24 h-9"
											alt=""
											src="./decrement-icon.svg"
										/>
									</div>
									{/**increment amount and update betvalue */}
									<div
										onClick={() =>
											setBetValue((prevVal) => prevVal + currValue)
										}
									>
										<img
											className="w-24 h-9"
											alt=""
											src="./increment-icon.svg"
										/>
									</div>
								</div>
							</div>
							{gameOn ? SequncialCards() : null}
							<div className="mt-8">
								<Tabs
									EveryoneContent={
										<EveryoneContent buffer={buffer} key="everyone-content" />
									}
									MyOrdersContent={<HiloMyOrders key="my-orders-content" />}
								/>
							</div>
						</div>
					</div>
					<ResultModal
						showModal={showModal}
						setShowModal={setShowModal}
						res={gameResulte}
						setGameOn={setGameOn}
						setGameResult={setGameResult}
						setAllCard={setAllCard}
						allCard={allCard}
						gameResulte={gameResulte}
					/>
					<RechargeModal
						rechargeModal={rechargeModal}
						setRechargeModal={setRechargeModal}
					/>
				</>
			</GameLayout>
		</>
	);

	function SequncialCards() {
		return (
			<div className="mt-5 flex flex-col items-center justify-center text-xs">
				<div className="w-80 overflow-x-auto">
					<div className="flex items-center justify-center h-[100px] text-[15.08px] overflow-x-auto">
						<div className="flex items-center justify-start">
							{allCard?.map((data, index) => {
								let arrowImg = null;
								if (data.side === "up") {
									arrowImg = "./assets/arrows/green-arrow-up.svg";
								} else if (data.side === "down") {
									arrowImg = "./assets/arrows/green-arrow-down.svg";
								}
								if (gameResulte !== null) {
									if (
										data.side === "up" &&
										gameResulte.result === "lose" &&
										allCard?.length - 1 === index
									) {
										arrowImg = "./assets/arrows/red-arrow-down.svg";
									} else if (
										data.side === "down" &&
										gameResulte.result === "lose" &&
										allCard?.length - 1 === index
									) {
										arrowImg = "/assets/arrows/red-arrow-up.svg";
									}
								}
								return (
									<React.Fragment key={index}>
										{data.side !== null ? (
											<img
												className="relative w-[33px] h-[34px] -mx-1 z-10"
												alt=""
												src={arrowImg}
											/>
										) : null}
										<div className="relative w-[70.93px] h-[92px]">
											<img
												src={
													data.type === "h"
														? "./assets/cards/dil.svg"
														: data.type === "d"
															? "./assets/cards/charkat.svg"
															: data.type === "s"
																? "./assets/cards/kali.svg"
																: "./assets/cards/fali.svg"
												}
												alt="cards"
												width="100%"
												height="100%"
											/>
											<b
												className={`absolute top-[36.63px]  ${cardDetails.card.val == 1 &&
													"left-[calc(50%_-_4.47px)]"
													}
           

${cardDetails.card.val < 10 &&
													cardDetails.card.val > 1 &&
													"left-[calc(50%_-_4.47px)]"
													}
                          ${cardDetails.card.val == 10 &&
													"left-[calc(50%_-_9.47px)]"
													}
                          ${cardDetails.card.val == 11 &&
													"left-[calc(50%_-_3.47px)]"
													} ${cardDetails.card.val == 12 &&
													"left-[calc(50%_-_6.47px)]"
													} 
${cardDetails.card.val == 13 && "left-[calc(50%_-_5.47px)]"} 



`}
											>
												{data.card == 11
													? "J"
													: data.card == 12
														? "Q"
														: data.card == 13
															? "K"
															: data.card == 1
																? "A"
																: data.card}
											</b>
										</div>
									</React.Fragment>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default HiloPage;

const EveryoneContent = ({ buffer }) => (
	<>
		<div className="rounded-xl flex flex-col items-center justify-center text-xs ">
			{buffer?.length === 0 ? null : (
				<div className="flex flex-row py-[5px] w-[100%] mt-2 px-0 items-center justify-center border-b-[1px] border-solid border-white">
					<div className="w-[137px] flex flex-row py-1.5 px-3.5 box-border items-center justify-start">
						<b className="relative">Period</b>
					</div>
					<div className="w-[69px] flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
						<b className="relative">User</b>
					</div>
					<div className="w-[68px] flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
						<b className="relative">pass</b>
					</div>
					<div className="w-[69px] flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
						<b className="relative">Win</b>
					</div>
				</div>
			)}

			{buffer?.length === 0 ? (
				<div className="mt-5">No records</div>
			) : (
				buffer?.map((data, index) => {
					return (
						<React.Fragment key={index}>
							<div className="flex flex-row py-[5px] px-0 items-center justify-center">
								<div className="w-[137px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-start">
									<div className="relative">{data.game_time}</div>
								</div>
								<div className="w-[69px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
									<div className="relative">{data.user_name}</div>
								</div>
								<div className="w-[69px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
									<div className="relative">{data.card_count}</div>
								</div>
								{data.winning_amount && (
									<div className="w-[69px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
										<div className="relative text-lightgreen">
											Rs.{data.winning_amount?.toFixed(2)}
										</div>
									</div>
								)}
								{data.losing_amount && (
									<div className="w-[69px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
										<div className="relative text-tomato">
											Rs.{data.losing_amount?.toFixed(2)}
										</div>
									</div>
								)}
							</div>
						</React.Fragment>
					);
				})
			)}
		</div>
	</>
);
