import React, { useEffect, useState } from "react";
import { formatTime } from "../../shared/format-time";
import { notifyError } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import { fetchGameHistory } from "../../services/backendServices";

const AndarBaharRecords = ({ buffer }) => {
	const [activeTab, setActiveTab] = useState("everyone");
	const navigate = useNavigate();
	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	const [gameHistory, setGameHistory] = useState([]);

	useEffect(() => {
		const gameName = "andarbahar";

		fetchGameHistory(gameName)
			.then((res) => {
				if (res.status) {
					notifyError(res.msg);
				} else {
					setGameHistory(res.game_data);
				}
			})
			.catch((error) => {
				console.error("Error fetching andarbahar game history:", error);
			});
	}, [activeTab]);

	return (
		<>
			<div className="flex justify-center items-center w-[100%] mb-10">
				<div className="relative my-4 w-[96%] rounded-xl bg-darkslategray h-fit md:max-h-[calc(100vh_-_142px)] text-xs">
					<div className="p-2 w-[96%] ">
						<div className="flex">
							<div
								className={`box-border flex-1 flex flex-row p-2.5 items-center justify-center text-sm border-b-[2px] border-solid  ${
									activeTab === "everyone"
										? "border-blue-main"
										: "border-dark-blue"
								}`}
								onClick={() => handleTabChange("everyone")}
							>
								<div className="relative">Everyone orders</div>
							</div>
							<div
								className={`box-border flex-1 flex flex-row p-2.5 items-center justify-center text-sm border-b-[2px] border-solid ${
									activeTab === "myOrders"
										? "border-blue-main"
										: "border-dark-blue"
								}`}
								onClick={() => handleTabChange("myOrders")}
							>
								<div className="relative">My orders</div>
							</div>
						</div>
						{activeTab === "everyone" ? (
							<>
								{buffer?.length === 0 ? null : (
									<div className="flex flex-row mt-4 py-[5px] px-0 items-center justify-center border-b-[1px] border-solid border-white">
										<div className="w-[137px] flex flex-row py-1.5 px-3.5 box-border items-center justify-start">
											<b className="relative">Period</b>
										</div>
										<div className="w-[69px] flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
											<b className="relative">User</b>
										</div>
										<div className="w-[68px] flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
											<b className="relative">Select</b>
										</div>
										<div className="w-[69px] flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
											<b className="relative">Point</b>
										</div>
									</div>
								)}
								{buffer?.length === 0 ? (
									<div className="mt-5 flex justify-center">No records</div>
								) : (
									buffer?.map((data, index) => {
										return (
											<React.Fragment key={index}>
												<div className="flex flex-row py-[5px] px-0 items-center justify-center">
													<div className="w-[137px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-start">
														<div className="relative">{data.game_time}</div>
													</div>
													<div className="w-[69px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
														<div className="relative">{data.user_name}</div>
													</div>
													<div className="w-[68px] flex flex-row py-1.5 px-3.5 box-border items-center justify-center text-center">
														<div className="relative w-5 h-5">
															<div
																className={`absolute top-[0px] left-[0px] rounded-[50%]   ${
																	data.betting_side === "bahar"
																		? "B"
																		: data.betting_side === "tie"
																		? "bg-orange-200"
																		: "bg-dodgerblue-200"
																}w-5 h-5`}
															/>
															<b className="absolute top-[3px] left-[calc(50%_-_5px)] inline-block w-2.5">
																{data.betting_side === "bahar"
																	? "B"
																	: data.betting_side === "tie"
																	? "T"
																	: "A"}
															</b>
														</div>
													</div>
													{data.winning_amount && (
														<div className="w-[69px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
															<div className="relative text-lightgreen">
																+{data.winning_amount}
															</div>
														</div>
													)}
													{data.losing_amount && (
														<div className="w-[69px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
															<div className="relative text-tomato">
																{data.losing_amount}
															</div>
														</div>
													)}
												</div>
											</React.Fragment>
										);
									})
								)}
							</>
						) : (
							<>
								{gameHistory?.length === 0 ? (
									<div className="mt-5 flex justify-center">No records</div>
								) : (
									<div className="flex flex-col items-center justify-center p-4 text-center ">
										{gameHistory?.map((data, index) => {
											const selected_card =
												data.selected_card && JSON.parse(data.selected_card);
											return (
												<div
													className="relative flex rounded-xl p-4 gap-3 m-2 border-[1px] border-solid border-white w-[90%] md:w-80"
													key={index}
												>
													<div className="flex-1 w-1/2">
														<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap">
															<div className="relative">
																<span>{`Result: `}</span>
																<b
																	className={`  ${
																		data.is_win
																			? "text-lightgreen"
																			: "text-tomato"
																	}`}
																>
																	{data.is_win ? "win" : "lose"}
																</b>
															</div>
														</div>
														<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap">
															<div className="relative">
																<span>{`Time: `}</span>
																<b>{formatTime(data.end_time)}</b>
															</div>
														</div>
														<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap">
															<div className="relative">
																<span>{`point: `}</span>
																<b>{data.betting_amount}</b>
															</div>
														</div>
													</div>
													<div className="flex-1 w-1/2">
														<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap">
															<div className="relative">
																<span>{`Amount: `}</span>
																<b
																	className={`  ${
																		data.is_win
																			? "text-lightgreen"
																			: "text-tomato"
																	}`}
																>
																	{data.is_win
																		? `+${data.winning_amount}`
																		: `-${data.betting_amount}`}
																</b>
															</div>
														</div>
														<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap ">
															<div className="relative">
																<span>{`Side: ${data.betting_side}`}</span>
																<b></b>
															</div>
														</div>
														<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap">
															<div className="relative">
																<span>{`Card: ${selected_card?.val}`}</span>
															</div>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default AndarBaharRecords;
