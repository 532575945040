import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
export const AUTH_TOKEN = "auth-token-win";
export const USER_DATA = "user-data-win";
export const LUDO_PLAYER_ID = "ludo-player-id";
export const TABLE_ID = "table-id";
export const LUDO_PLAYER_COLOR = "ludo-player-color";

// export const API_URL = "http://192.168.1.8:3000/api/v2";
// export const SOCKET_URL = "ws://192.168.1.8:4000";

export const API_URL = "https://api.winyar.com/api/v2";
export const SOCKET_URL = "ws://socket.winyar.com";

export const CRYPTO_WALLET = "0x8566f64C4Eb521411B0a14E462b54F0F2a3898Ed"

export const APP_ENV = "prod";
export const APP_VERSION = "v1.3.1";

export function getToken() {
	const authtoken = localStorage.getItem(AUTH_TOKEN);
	return authtoken;
}

export function getUserName() {
	const authtoken = localStorage.getItem(AUTH_TOKEN);
	const data = jwt_decode(authtoken);
	return data?.userName;
}

export function removeLocalStorage() {
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem(USER_DATA);
}

export function getUserBalance() {
	const data =
		localStorage.getItem(USER_DATA) !== undefined &&
		JSON.parse(localStorage.getItem(USER_DATA));
	return data?.user_balance;
}

export function checkUserBlockStatus() {
	const data =
		localStorage.getItem(USER_DATA) !== undefined &&
		JSON.parse(localStorage.getItem(USER_DATA));
	return data?.is_disabled;
}

export function getSocketId() {
	const data = localStorage.getItem(TABLE_ID);
	return data;
}

export function setSocketId(data) {
	const socketId = JSON.stringify(data);
	localStorage.setItem(TABLE_ID, socketId);
}

export const notifyError = (msg) => {
	toast.error(msg, {
		position: "top-center",
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		theme: "dark",
	});
};

export const notifySuccess = (msg) => {
	toast.success(msg, {
		position: "top-center",
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		theme: "dark",
	});
};
