import React, { useEffect, useState } from "react";
import PrimaryButton from "../../widgets/primary-button";
import OutlineButton from "../../widgets/outline-button";
import { notifyError } from "../../utils/constant";
import UserBlockModel from "../../shared/user-block-model";
import { getDashboardData } from "../../services/backendServices";

const WalletCard = () => {
	const [userBalance, setUserBalance] = useState(0);
	const [isBlock, setIsBlock] = useState(false);
	const fetchData = async () => {
		try {
			const res = await getDashboardData();
			if (res.status) {
				setUserBalance(Number(res.user_balance));
				setIsBlock(res.is_disabled);
			} else {
				notifyError(res.msg);
			}
		} catch (error) {
			console.log("error in fetch dashbordata", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<div className="flex p-4 rounded-xl md:w-[360px] z-[0] bg-blue-main flex-col items-start justify-start gap-[10px]">
				<div className="w-full flex flex-col items-start justify-start gap-[12px] z-[1]">
					<div className="flex flex-col items-start justify-start">
						<div className="flex flex-row items-center justify-start gap-[4px]">
							<div className="box-border flex flex-col items-center justify-center p-2 w-9">
								<img
									className="relative w-7 h-[22.79px]"
									alt=""
									src="./assets/menu/wallet-icon.svg"
								/>
							</div>
							<b className="relative">{userBalance?.toFixed(2)}</b>
							<div
								className="flex flex-col items-center justify-center p-2"
								onClick={() => fetchData()}
							>
								<img
									className="relative w-[22px] h-[19.95px]"
									alt=""
									src="./vector7.svg"
								/>
							</div>
						</div>
					</div>
					<div className="flex flex-row items-start justify-around gap-[16px] text-sm">
						<PrimaryButton
							text="Recharge"
							isBlock={isBlock}
							setIsBlock={setIsBlock}
						/>
						<OutlineButton
							text="Withdraw"
							isBlock={isBlock}
							setIsBlock={setIsBlock}
						/>
					</div>
				</div>
			</div>
			<UserBlockModel isBlock={isBlock} setIsBlock={setIsBlock} />
		</>
	);
};

export default WalletCard;
