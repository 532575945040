// socket.js
import io from "socket.io-client";
import { SOCKET_URL, getToken } from "../../utils/constant";

export let ludoSocketInstance;

export const connectSocket = (table_id) => {
	const token = getToken();
	const IP = SOCKET_URL;

	ludoSocketInstance = io(IP, {
		transports: ["websocket", "polling"],
		query: {
			game: "ludo",
			authorization: token,
			table_id: table_id,
		},
	});

	ludoSocketInstance.on("connect", () => {
		console.log("Connected to LUDO Socket");
	});

	ludoSocketInstance.on("disconnect", () => {
		console.log("Disconnected from LUDO Socket");
	});
};

export const reConnectSocket = (id) => {
	const token = getToken();
	const IP = SOCKET_URL;

	ludoSocketInstance = io(IP, {
		transports: ["websocket", "polling"],
		query: {
			game: "ludo",
			authorization: token,
			session_id: id,
			is_rejoin: true,
		},
	});


	ludoSocketInstance.on("connect", () => {
		console.log("Re-Connected to LUDO Socket");
	});

	ludoSocketInstance.on("disconnect", () => {
		console.log("Reconnect : Disconnected from LUDO Socket");
	});
};

export const CustomRoomSocket = (amount) => {
	const token = getToken();
	const IP = SOCKET_URL;

	ludoSocketInstance = io(IP, {
		transports: ["websocket", "polling"],
		query: {
			game: "ludo",
			authorization: token,
			is_custom: true,
			is_creator: true,
			bet_amount: amount,
		},
	});

	ludoSocketInstance.on("connect", () => {
		console.log("Custom Room Socket Connected to LUDO Socket");
	});

	ludoSocketInstance.on("disconnect", () => {
		console.log("Custom Room Socket Disconnected from LUDO Socket");
	});
};

export const JoinRoomSocket = (table_key) => {
	const token = getToken();
	const IP = SOCKET_URL;

	ludoSocketInstance = io(IP, {
		transports: ["websocket", "polling"],
		query: {
			game: "ludo",
			authorization: token,
			is_custom: true,
			table_key: table_key,
		},
	});

	ludoSocketInstance.on("connect", () => {
		console.log("Join Room Socket Connected to LUDO Socket");
	});

	ludoSocketInstance.on("disconnect", () => {
		console.log("Join Room Socket Disconnected from LUDO Socket");
	});
};
