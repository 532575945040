import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getToken, notifySuccess } from "../utils/constant";

import { forgotPassword, sendOTPRequest } from "../services/backendServices";

function ForgotPassword() {
	const [username, setUsername] = useState("");
	const [password, setPassowrd] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [OTP, setOTP] = useState("");
	const [disableButton, setDisableButton] = useState(false);
	const [timer, setTimer] = useState(30);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};
	const token = getToken();
	useEffect(() => {
		if (token) {
			navigate("/dashboard");
		}
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setError(null);
		try {
			setLoading(true);
			const res = await forgotPassword(username, password, OTP);
			if (res.status) {
				notifySuccess(res.msg);
				navigate("/login");
			} else {
				setError(res.msg);
				setTimeout(() => {
					setError();
				}, 2000);
			}
		} catch (error) {
			setError("Error, Please try again.");
			setTimeout(() => {
				setError();
			}, 2000);
			console.error("Error in update password:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		let interval;

		if (disableButton) {
			interval = setInterval(() => {
				setTimer((prevTimer) => prevTimer - 1);
			}, 1000);

			if (timer === 0) {
				clearInterval(interval);
				setDisableButton(false);
				setTimer(30);
			}
		} else {
			clearInterval(interval);
		}

		return () => clearInterval(interval);
	}, [disableButton, timer]);

	const handleGetOTP = async (e) => {
		if (username?.length !== 10) {
			setError("please enter valid 10 digit Number");
			setTimeout(() => {
				setError();
			}, 2000);
			return;
		} else {
			// Reset previous errors
			setError(null);
			e.preventDefault();
			try {
				const res = await sendOTPRequest(username, true);
				if (res.status) {
					setDisableButton(true);
				} else {
					setError(res.msg);
					setTimeout(() => {
						setError();
					}, 2000);
					setDisableButton(false);
				}
			} catch (error) {
				setError("Error, Please try again.");
				setTimeout(() => {
					setError();
				}, 2000);
				console.error("Error in handleGetOTP:", error);
			}
		}
	};

	const isUsernameValid = username?.length === 10;

	return (
		<>
			<div
				className="relative w-screen h-screen overflow-hidden text-5xl text-left text-white bg-backgound font-fs-elliot-pro"
				style={{
					backgroundImage: "url('./app-bg.svg')",
				}}
			>
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<h2 className="mt-10 text-center text-xl leading-9 text-white">
						Forgot Password
					</h2>
				</div>

				<div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={handleSubmit}>
						<div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
							<label className="mt-5 block text-sm font-medium leading-6 text-gray-900 px-7">
								Mobile No:
							</label>
							<div className="items-center justify-evenly flex">
								<input
									className={`rounded-8xs bg-gray ${
										disableButton ? "w-[73%]" : "w-[65%]"
									} h-[35px] flex flex-row py-3.5 px-5 box-border text-[16px]`}
									type="number"
									required
									placeholder="Enter your mobile number"
									onChange={(e) => setUsername(e.target.value)}
								/>
								<button
									onClick={handleGetOTP}
									className={`w-auto mt-1 rounded-md bg-orange-100 text-dark-blue px-2 py-1.5 text-sm font-semibold leading-6 text-dark-blue shadow-sm hover:bg-orange-200 ${
										disableButton || !isUsernameValid
											? "opacity-50 cursor-not-allowed"
											: ""
									}`}
									disabled={!isUsernameValid || disableButton}
								>
									{disableButton ? ` (${timer}s)` : "Send OTP"}
								</button>
							</div>
						</div>

						<div>
							<div>
								<label className="block text-sm font-medium leading-6 text-gray-900 px-7">
									Password
								</label>
							</div>
							<div>
								<div className="relative items-center justify-center flex">
									<input
										className="rounded-8xs bg-gray w-[90%] h-[35px] flex flex-row py-3.5 px-5 box-border text-[16px]"
										id="password"
										name="password"
										type={showPassword ? "text" : "password"}
										autoComplete="current-password"
										placeholder="Enter your password"
										required
										value={password}
										minLength="8"
										onChange={(e) => setPassowrd(e.target.value)}
									/>
									<div
										className="absolute right-[25px] top-1/2 transform -translate-y-1/2 flex items-center text-gray-600"
										onClick={togglePasswordVisibility}
									>
										{showPassword ? (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-7 h-7"
												style={{ background: "none" }}
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
												/>
											</svg>
										) : (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-7 h-7"
												style={{ background: "none" }}
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
												/>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
												/>
											</svg>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className="mt-2">
							<div>
								<label className="block text-sm font-medium leading-6 text-gray-900 px-7">
									Confirm Password
								</label>
							</div>
							<div>
								<div className="relative items-center justify-center flex">
									<input
										className="rounded-8xs bg-gray w-[90%] h-[35px] flex flex-row py-3.5 px-5 box-border text-[16px]"
										id="confirm-password"
										name="confirm-password"
										type={showConfirmPassword ? "text" : "password"}
										autoComplete="current-password"
										placeholder="Confirm password"
										required
										value={confirmPassword}
										minLength="8"
										onChange={(e) => setConfirmPassword(e.target.value)}
									/>
									<div
										className="absolute right-[25px] top-1/2 transform -translate-y-1/2 flex items-center text-gray-600"
										onClick={toggleConfirmPasswordVisibility}
									>
										{showConfirmPassword ? (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-7 h-7"
												style={{ background: "none" }}
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
												/>
											</svg>
										) : (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-7 h-7"
												style={{ background: "none" }}
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
												/>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
												/>
											</svg>
										)}
									</div>
								</div>
							</div>
						</div>
						{isUsernameValid && (
							<div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
								<label className="mt-5 block text-sm font-medium leading-6 text-gray-900 px-7">
									Verify OTP
								</label>
								<div className="items-center justify-evenly flex">
									<input
										className="rounded-8xs bg-gray w-[90%] h-[35px] flex flex-row py-3.5 px-5 box-border text-[16px]"
										type="text"
										placeholder="Enter your OTP"
										required
										value={OTP}
										onChange={(e) => setOTP(e.target.value)}
									/>
								</div>
							</div>
						)}

						<div className="justify-center flex">
							<button
								type="submit"
								className="w-[90%] mt-2 rounded-md bg-orange-100 text-dark-blue px-3 py-1.5 text-sm font-semibold leading-6 text-dark-blue shadow-sm hover:bg-orange-200"
							>
								{loading ? "Loading..." : "Confirm"}
							</button>
						</div>
					</form>
					{error && (
						<p className="mt-2 text-center text-red-500 text-xs">{error}</p>
					)}
				</div>
				<div className="h-[35px] mt-5 flex flex-row items-center justify-center text-lightslategray">
					<div onClick={() => navigate("/login")}>
						<div className="h-[35px] overflow-hidden flex flex-row box-border items-center justify-center">
							<img
								className="relative w-2.5 h-[15.47px]"
								alt=""
								src="./vector5.svg"
							/>
							<p className="relative text-base">
								&nbsp;&nbsp;<u>BACK</u>
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ForgotPassword;
