import GameLayout from "../shared/game-layout";
import ParityDesign from "../components/parity/parityDesign";

import SubHeader from "../shared/sub-header";
const ParityPage = () => {
	return (
		<>
			<GameLayout>
				<SubHeader subTitle="Parity" showRule />
				<ParityDesign />
			</GameLayout>
		</>
	);
};

export default ParityPage;
