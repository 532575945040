import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigationType,
} from "react-router-dom";
import { AndarBaharPage, HiloPage, HomePage, ParityPage } from "./pages";

import { useEffect, lazy } from "react";
import Login from "./pages/login";
import Register from "./pages/register";
import Recharge from "./pages/recharge";
import AddBalance from "./components/recharge/add-balance";
import Payment from "./components/recharge/payment";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PrivateRoute from "./routes/private-route";
import ForgotPassword from "./pages/forgot-password";

import LudoOnlinePlay from "./components/ludo/ludo-online-play";
import LudoPlay from "./components/ludo/ludo-play";
import Ludo from "./pages/ludo";
import { APP_VERSION } from "./utils/constant";
import AutoJoin from "./components/ludo/autojoin";
const My = lazy(() => import("./pages/my"));
const withdraw = lazy(() => import("./pages/withdraw"));

const Orders = lazy(() => import("./components/my/orders"));
const PaymentOrders = lazy(() => import("./components/my/payment-orders"));
const BankDetails = lazy(() => import("./components/withdraw/bank-details"));
const Invite = lazy(() => import("./pages/invite"));
const CryptoMethod = lazy(() => import("./components/recharge/crypto-method"));
const UpiMethod = lazy(() => import("./components/recharge/upi-method"));
const Upiwithdraw = lazy(() => import("./components/withdraw/upi-withdraw"));
const ChnagePaymentType = lazy(() =>
	import("./components/recharge/change-payment-type")
);

const Aviator = lazy(() => import("./pages/aviator"));

const CreateCustomRoom = lazy(() => import("./components/ludo/create-room"));
const WaitingSccreen = lazy(() => import("./components/ludo/waiting-screen"));

function App() {
	console.log(APP_VERSION);
	const action = useNavigationType();
	const location = useLocation();
	const pathname = location.pathname;

	useEffect(() => {
		if (action !== "POP") {
			window.scrollTo(0, 0);
		}
	}, [action, pathname]);

	useEffect(() => {
		let title = "";
		let metaDescription = "";

		switch (pathname) {
			case "/dashboard":
				title = "";
				metaDescription = "";
				break;
			// case "/aviator":
			// 	title = "";
			// 	metaDescription = "";
			// 	break;
			case "/hilo":
				title = "";
				metaDescription = "";
				break;
			case "/andar-bahar":
				title = "";
				metaDescription = "";
				break;
			case "/parity":
				title = "";
				metaDescription = "";
				break;
			default:
				title = "";
				metaDescription = "";
		}

		if (title) {
			document.title = title;
		}

		if (metaDescription) {
			const metaDescriptionTag = document.querySelector(
				'head > meta[name="description"]'
			);
			if (metaDescriptionTag) {
				metaDescriptionTag.content = metaDescription;
			}
		}
	}, [pathname]);

	return (
		<>
			<Routes>
				<Route path="/" element={<Navigate to="/login" />} />
				<Route
					path="/dashboard"
					element={<PrivateRoute component={HomePage} />}
				/>
				<Route path="/invite" element={<PrivateRoute component={Invite} />} />
				<Route
					path="/andar-bahar"
					element={<PrivateRoute component={AndarBaharPage} />}
				/>
				<Route path="/hilo" element={<PrivateRoute component={HiloPage} />} />
				{/* <Route path="aviator" element={<PrivateRoute component={Aviator} />} /> */}
				<Route
					path="/parity"
					element={<PrivateRoute component={ParityPage} />}
				/>
				<Route
					path="/recharge"
					element={<PrivateRoute component={Recharge} />}
				/>

				{/* 
				<Route
					path="/crypto-payment"
					element={<PrivateRoute component={CryptoMethod} />}
				/>
				<Route
					path="/upi-payment"
					element={<PrivateRoute component={UpiMethod} />}
				/>
				<Route
					path="/add-money"
					element={<PrivateRoute component={AddBalance} />}
				/> 
				<Route
					path="/change-payment-type"
					element={<PrivateRoute component={ChnagePaymentType} />}
				/>
               <Route path="/payment" element={<PrivateRoute component={Payment} />} />

				*/}
				<Route path="/my" element={<PrivateRoute component={My} />} />

				<Route
					path="/withdraw"
					element={<PrivateRoute component={Upiwithdraw} />}
				/>
				<Route
					path="/confirm"
					element={<PrivateRoute component={BankDetails} />}
				/>
				{/* <Route
					path="/upi-withdraw"
					element={<PrivateRoute component={Upiwithdraw} />}
				/> */}
				{/** Router configuration is set up to accept an optional game parameter */}
				<Route
					path="/records/:game?"
					element={<PrivateRoute component={Orders} />}
				/>
				<Route
					path="/payment-records"
					element={<PrivateRoute component={PaymentOrders} />}
				/>
				<Route path="/ludo" element={<PrivateRoute component={Ludo} />} />
				<Route
					path="/ludo-online-play"
					element={<PrivateRoute component={LudoOnlinePlay} />}
				/>
				<Route
					path="/ludo-room"
					element={<PrivateRoute component={CreateCustomRoom} />}
				/>
				<Route
					path="/joinroom/:roomcode"
					element={<PrivateRoute component={AutoJoin} />}
				/>
				<Route
					path="/ludo-waiting"
					element={<PrivateRoute component={WaitingSccreen} />}
				/>
				<Route
					path="/ludo-play"
					element={<PrivateRoute component={LudoPlay} />}
				/>

				<Route path="/register" element={<Register />} />

				<Route
					path="/invite/referralcode/:referralCode"
					element={<Register />}
				/>

				<Route path="/login" element={<Login />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="*" element={<Navigate to="/login" />} />
			</Routes>
			<ToastContainer
				position="top-center"
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
				toastStyle={{ backgroundColor: "#2a314a" }}
				transition={Slide}
			/>
		</>
	);
}
export default App;
