import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import RechargeModal from "../../shared/recharge-modal";
import { connectSocket, ludoSocketInstance } from "./ludo-socket";
import ResponseModel from "../../shared/response-model";
import { notifyError } from "../../utils/constant";
import { fetchLudoContest } from "../../services/backendServices";

function LudoOnlinePlay() {
	const [numOfPlayers, setNumberOfPlayers] = useState(2);
	const [currentContest, setCurrentContest] = useState(0);
	const [totalContests, setTotalContests] = useState(1);
	const [currentContestCard, setCurrentContestCard] = useState(0);
	const totalContestCards = 4;
	const navigate = useNavigate();
	const [data, setData] = useState({ persons2Array: [], persons4Array: [] });

	const handlePrevious = () => {
		setCurrentContest((prevContest) =>
			prevContest === 0 ? totalContests - 1 : prevContest - 1
		);
		setCurrentContestCard((prevContest) =>
			prevContest === 0 ? totalContestCards - 1 : prevContest - 1
		);
	};

	const handleNext = () => {
		setCurrentContest((prevContest) =>
			prevContest === totalContests - 1 ? -1 : prevContest + 1
		);
		setCurrentContestCard((prevContest) =>
			prevContest === totalContestCards - 1 ? 0 : prevContest + 1
		);
	};

	const fetchData = async () => {
		try {
			const res = await fetchLudoContest();

			if (!res.status) {
				notifyError(res.msg);
			} else {
				const { data } = res;
				const persons2Array = [];
				const persons4Array = [];

				data.forEach((item) => {
					if (item.number_of_persons == 2) {
						persons2Array.push(item);
					} else if (item.number_of_persons == 4) {
						persons4Array.push(item);
					}
				});

				setData({ persons2Array, persons4Array });
				setTotalContests(persons2Array.length);
			}
		} catch (error) {
			console.log("error in ludo contest api : ", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {

		if (numOfPlayers == 2 && data.persons2Array) {
			setTotalContests(data.persons2Array.length);
		} else if (numOfPlayers == 4 && data.persons4Array) {
			setTotalContests(data.persons4Array.length);
		}
	}, [numOfPlayers]);

	const [rechargeModal, setRechargeModal] = useState(false);
	const [showModal, setShowModal] = useState({ show: false, msg: "" });

	const handlePlay = () => {
		let id;
		if (numOfPlayers == 2) {
			id = data.persons2Array[currentContest]?.id;
		} else {
			id = data.persons4Array[currentContest]?.id;
		}

		connectSocket(id);

		ludoSocketInstance?.on("resJoinFailed", (res) => {
			// console.log("resJoinFailed");
			setShowModal({ show: true, msg: res.msg });
		});

		ludoSocketInstance?.on("resInsufficientBalance", () => {
			// console.log("resInsufficientBalance");
			setRechargeModal(true);
		});

		ludoSocketInstance?.on("resPlayerIndex", (data) => {
			// console.log("resPlayerIndex");
		});

		ludoSocketInstance?.on("resWaitingForPlayers", () => {
			// console.log("waiting");
			navigate("/ludo-waiting");
		});

		ludoSocketInstance?.on("resGameLoading", () => {
			// console.log("resGameLoading navigate");
			navigate("/ludo-play");
		});
	};
	return (
		<>
			<div
				className="flex flex-col items-center justify-center fixed inset-0 z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
				style={{ backgroundImage: "url('./assets/ludo/home_bg.svg')" }}
			>
				<div
					className="w-[80%] bg-no-repeat bg-inherit pb-5 "
					style={{
						backgroundImage: 'url("./assets/ludo/pop-up.png")',
						backgroundPosition: "center",
						border: "solid 5px #F5C913",
						borderRadius: "20px",
						maxWidth: "500px",
					}}
				>
					<div className="flex flex-col justify-center items-center mt-3">
						<div className="text-9xl font-bold" style={{ color: "#F5C913" }}>
							SELECT PLAYERS
						</div>
						<div
							className="flex justify-center items-center mt-5"
							style={{ maxWidth: "fit-content" }}
							onClick={() => setNumberOfPlayers(2)}
						>
							<img
								src={
									numOfPlayers === 2
										? "./assets/ludo/with-tick.svg"
										: "./assets/ludo/without-tick.svg"
								}
							/>
							<div className="text-5xl font-bold" style={{ color: "white" }}>
								&nbsp;2 PLAYERS
							</div>
						</div>
						<div
							className="flex justify-center items-center mt-3"
							onClick={() => setNumberOfPlayers(4)}
							style={{ maxWidth: "fit-content" }}
						>
							<img
								src={
									numOfPlayers === 4
										? "./assets/ludo/with-tick.svg"
										: "./assets/ludo/without-tick.svg"
								}
							/>
							<div className="text-5xl font-bold" style={{ color: "white" }}>
								&nbsp;4 PLAYERS
							</div>
						</div>
					</div>
				</div>
				<div
					className="w-[80%] bg-no-repeat bg-inherit pb-5 mt-5"
					style={{
						backgroundImage: 'url("./assets/ludo/pop-up-box.svg")',
						backgroundPosition: "center",
						border: "solid 5px #F5C913",
						borderRadius: "20px",
						maxWidth: "500px",
					}}
				>
					<div className="flex flex-col justify-center items-center mt-3">
						<div className="text-9xl font-bold" style={{ color: "#F5C913" }}>
							SELECT GAME
						</div>
						<div
							className="flex justify-center items-center mt-5"
							style={{ maxWidth: "fit-content" }}
						>
							<div style={{ position: "relative", width: "130px" }}>
								{" "}
								{/* Adjust width according to your image size */}
								<img
									src={`./assets/ludo/card${currentContestCard}.svg`}
									width={130}
									style={{ borderRadius: "15px" }}
								/>
								<div
									style={{
										position: "absolute",
										top: "52%",
										left: "50%",
										transform: "translate(-50%, -50%)",
										fontSize: "1.3rem",
										color: "#FFFFFF",
										fontWeight: "bold",
										textAlign: "center",
										textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
										width: "100%",
									}}
								>
									{numOfPlayers == 2 &&
										data.persons2Array[currentContest]?.winning_amount}
									{numOfPlayers == 4 &&
										data.persons4Array[currentContest]?.winning_amount}
								</div>
								<div
									style={{
										position: "absolute",
										top: "85%",
										left: "50%",
										transform: "translate(-50%, -50%)",
										fontSize: "1.2rem",
										color: "#FFFFFF",
										fontWeight: "bold",
										textAlign: "center",
										textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
										width: "100%",
									}}
								>
									BET:{" "}
									{numOfPlayers == 2 &&
										data.persons2Array[currentContest]?.bet_amount}
									{numOfPlayers == 4 &&
										data.persons4Array[currentContest]?.bet_amount}
								</div>
								{currentContest !== 0 && (
									<div
										style={{
											position: "absolute",
											top: "50%",
											left: "-30%",
											transform: "translate(-50%, -50%)",
											fontSize: "1.2rem",
											color: "#FFFFFF",
											fontWeight: "bold",
											textAlign: "center",
											textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
											width: "100%",
										}}
										onClick={handlePrevious}
									>
										<i
											className="fa-solid fa-arrow-left p-2"
											style={{
												border: "solid 3px white",
												borderRadius: "10px",
											}}
										></i>
									</div>
								)}
								{currentContest !== totalContests - 1 && (
									<div
										style={{
											position: "absolute",
											top: "50%",
											left: "130%",
											transform: "translate(-50%, -50%)",
											fontSize: "1.2rem",
											color: "#FFFFFF",
											fontWeight: "bold",
											textAlign: "center",
											textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
											width: "100%",
										}}
										onClick={handleNext}
									>
										<i
											className="fa-solid fa-arrow-right p-2"
											style={{
												border: "solid 3px white",
												borderRadius: "10px",
											}}
										></i>
									</div>
								)}
							</div>
						</div>
						<div
							className="text-5xl font-bold mt-7 px-4"
							style={{
								color: "#F5C913",
								border: "solid 4px #F5C913",
								borderRadius: "20px",
							}}
							onClick={handlePlay}
						>
							PLAY
						</div>
					</div>
				</div>
				<div className="w-[80%] mt-5">
					<div
						className="w-fit bg-no-repeat bg-inherit px-2 py-0"
						style={{
							backgroundImage: 'url("./assets/ludo/pop-up-box.svg")',
							border: "solid 5px #F5C913",
							borderRadius: "20px",
							maxWidth: "500px",
						}}
					>
						<div
							onClick={() => {
								navigate("/ludo");
							}}
						>
							<img
								src={`./assets/ludo/u-turn.svg`}
								width={32}
								style={{ borderRadius: "20px" }}
							/>
						</div>
					</div>
				</div>
			</div>
			<RechargeModal
				rechargeModal={rechargeModal}
				setRechargeModal={setRechargeModal}
			/>
			<ResponseModel showModal={showModal} setShowModal={setShowModal} />
		</>
	);
}

export default LudoOnlinePlay;
