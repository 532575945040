import React, { useEffect, useRef, useState } from "react";
import BetModel from "./bet-model";
import { io } from "socket.io-client";
import AnadarBaharResultModal from "./result-model";
import AndarBaharRecords from "./records";
import "./card.css";
import { nanoid } from "nanoid";
import RechargeModal from "../../shared/recharge-modal";
import {
	SOCKET_URL,
	getToken,
	notifyError,
	removeLocalStorage,
} from "../../utils/constant";
import { useNavigate } from "react-router-dom";

import { getDashboardData } from "../../services/backendServices";

export let socketInstance = null;

const AndarBaharGameCards = () => {
	let confirm = useRef();
	const [bettingTime, setBettingTime] = useState();
	const [cardAndar, setCardAndar] = useState(null);
	const [cardBahar, setCardBahar] = useState(null);
	const [betAmount, setBetAmount] = useState(null);

	const [userSelect, setUserSelect] = useState();
	const [selectedCard, setSelectedCard] = useState(null);
	const [timer, setTimer] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [animationSide, setAnimationSide] = useState("");

	//show result model
	const [showResultModal, setResultShowModal] = useState(false);
	const [gameResult, setGameResult] = useState(null);
	const [rechargeModal, setRechargeModal] = useState(false);
	const [historyData, setHistoryData] = useState([]);
	const [totalBalance, setTotalBalance] = useState();

	// everyone's order
	const [buffer, setBuffer] = useState([]);
	const bufferSize = 6;

	const addToBuffer = (newValue) => {
		// If the buffer is already at its maximum size, remove the oldest value from the front
		if (buffer?.length >= bufferSize) {
			setBuffer((prevBuffer) => [...prevBuffer.slice(1), newValue]);
		} else {
			setBuffer((prevBuffer) => [...prevBuffer, newValue]);
		}
	};

	const navigate = useNavigate();

	const fetchData = async () => {
		try {
			const res = await getDashboardData();
			if (res.status) {
				setTotalBalance(Number(res.user_balance));
			} else {
				notifyError(res.msg);
			}
		} catch (error) {
			console.log("error in fetch dashbordata", error);
		}
	};

	useEffect(() => {
		const token = getToken();
		const IP = SOCKET_URL;

		// Create a new WebSocket connection
		socketInstance = io(IP, {
			transports: ["websocket", "polling"],
			query: {
				game: "andarbahar",
				authorization: token,
			},
		});

		socketInstance?.on("connect", () => {
			// console.log("Andarbahar WebSocket connected");
		});

		socketInstance?.on("remove_user", (msg) => {
			removeLocalStorage();
			setTimeout(() => {
				navigate("/login");
			}, 500);
		});

		socketInstance?.on("disconnect", () => {
			// console.log("Andarbahar WebSocket disconnected");
		});

		socketInstance?.on("resGameState", (res) => {
			// console.log("resGameState : ", res);
			const data = res.data.results_history.slice(1, 6);
			setBuffer(data);
			setHistoryData(res.data.last_fifteen_winning_side);

			handleTimer(res.data.isBettingOn);
			handleSelectedcard(res.data.selectedCard);
		});
		socketInstance?.on("andarbahar_latest_records", (res) => {
			// console.log('resGameState : ', res.data.isBettingOn)
			setHistoryData(res);
		});

		socketInstance?.on("resBettingTimeStart", () => {
			// console.log("start Time");
			handleTimer(true);
		});

		socketInstance?.on("resBettingTime", (res) => {
			// console.log("resBettingTime : ", res.data);
			setBettingTime(res.data);
		});

		socketInstance?.on("resBettingTimeEnd", (res) => {
			handleTimer(false);
		});

		socketInstance?.on("resBet", (res) => {
			// console.log("res bet", res);
			if (res.data.msg === "Insufficient balance") {
				setRechargeModal(true);
			} else {
				if (res.data.status) {
					setBetAmount(res.data.amount);
					confirm.current = true;
					handleTimer(false);
					setTotalBalance((prev) => prev - Number(res.data.amount));
				}
			}
		});

		socketInstance?.on("resCardOpen", (res) => {
			// console.log('resCardOpen : ', res)
			if (res.data.side === "andar") {
				setCardAndar(res.data.card);
				setAnimationSide("andar");
			} else {
				setCardBahar(res.data.card);
				setAnimationSide("bahar");
			}
		});
		socketInstance?.on("resSelectedCard", (res) => {
			// console.log('resSelectedCard : ', res)
			playCardFlipSound();
			handleSelectedcard(res);
		});

		socketInstance?.on("resCardMatch", (res) => {
			// handleCard(null);
			// handleSelectedcard(null);
			setTimeout(() => {
				handleCard(null);
				handleSelectedcard(null);
			}, 600);
			// console.log('resResult : ', res.data.cardSide)
		});

		socketInstance?.on("resResult", (data) => {
			// console.log('resResult : ', data.data.amount)
			setResultShowModal(true);
			confirm.current = false;
			setGameResult(data.data.amount);
			if (data.data.amount > 0) {
				fetchData();
			}
			setUserSelect("");
			setBetAmount(null);
		});

		socketInstance?.on("andarbahar_results", (res) => {
			addToBuffer(res);
		});

		fetchData();

		// Don't forget to close the socket when the component unmounts
		return () => {
			socketInstance.disconnect();
		};
	}, []);

	const handleCard = (val) => {
		setCardAndar(val);
		setCardBahar(val);
	};

	const handleSelectedcard = (card) => {
		setSelectedCard(card);
	};

	const handleTimer = (val) => {
		setTimer(val);
	};

	const formatTime = (time) => {
		if (time !== 1 && time) {
			// const remainingSeconds = time % 20
			return `00:${time?.toString()?.padStart(2, "0")}`;
		}
	};

	const handleUserSelect = (val) => {
		if (timer) {
			setUserSelect(val);
			setShowModal(true);
		}
	};

	if (buffer?.length > bufferSize) {
		setBuffer((prevBuffer) => [...prevBuffer.slice(1)]);
	}
	const audioRef = useRef(null);

	const playCardFlipSound = () => {
		audioRef.current.play().catch((error) => {
			console.error("Error playing audio:", error);
		});
	};

	return (
		<>
			<audio ref={audioRef} src="./assets/cards/cardAudio.wav" preload="auto" />
			{GrowthTags()}
			<div className="relative mb-4 mx-auto md:h-[calc(100vh_-_142px)]">
				<b className="block w-[90%] h-12 mx-3 text-center text-9xl">
					{formatTime(bettingTime?.bettingTime)}
				</b>
				<div className="flex items-center justify-between mb-10 w-80 md:w-96">
					<div className="flex-1"></div>
					<div className="flex justify-center flex-1">
						{selectedCard == null ? (
							<div className="rounded-[9.24px] bg-blue-main flex justify-center items-center box-border w-[100px] md:w-[107.15px] h-[140.25px] border-[1.8px] border-solid border-white">
								A-B
							</div>
						) : (
							<>
								<div className="animate-card relative w-[98px] md:w-[103.46px] h-[138.56px] text-3xl">
									<img
										src={
											selectedCard.type === "h"
												? "./assets/cards/dil.svg"
												: selectedCard.type === "d"
												? "./assets/cards/charkat.svg"
												: selectedCard.type === "s"
												? "./assets/cards/kali.svg"
												: "./assets/cards/fali.svg"
										}
										alt="cards"
										width="100%"
										height="100%"
									/>

									<b className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
										{selectedCard?.val == 11
											? "J"
											: selectedCard?.val == 12
											? "Q"
											: selectedCard?.val == 13
											? "K"
											: selectedCard?.val == 1
											? "A"
											: selectedCard?.val}
									</b>
								</div>
							</>
						)}
					</div>
					<div className="flex-1"></div>
				</div>
				<div className="flex items-center justify-between w-80 md:w-96">
					<div className="flex justify-center flex-1">
						{cardAndar && animationSide === "andar" ? (
							<AnimatedCard
								key={`${nanoid()}-andar`}
								card={cardAndar.val}
								anime={true}
								side="andar"
								type={cardAndar.type}
							/>
						) : (
							cardAndar && (
								<AnimatedCard
									key={`${nanoid()}-andar`}
									card={cardAndar.val}
									anime={false}
									side="andar"
									type={cardAndar.type}
								/>
							)
						)}
						{cardAndar == null ? (
							<div className="relative w-[98px] md:w-[103.46px] h-[138.56px] text-lg text-gray">
								<div className="md:absolute top-[0px] left-[0px] rounded-[9.24px] bg-white w-[98px] md:w-[103.46px] h-[138.56px]" />
								<b className="absolute top-[59px] left-[calc(50%_-_25.73px)]">
									Andar
								</b>
							</div>
						) : null}
					</div>
					<div className="flex justify-center flex-1">
						<div className="relative">
							<div className="rounded-[9.24px] bg-blue-main box-border w-[100px] md:w-[107.15px] h-[142.25px] border-[1.8px] border-solid border-white" />
							<div className="absolute -top-[7.5px] rounded-[9.24px] bg-blue-main box-border w-[100px] md:w-[107.15px] h-[142.25px] border-[1.8px] border-solid border-white" />
							<div className="absolute -top-[15px] rounded-[9.24px] bg-blue-main box-border w-[100px] md:w-[107.15px] h-[142.25px] border-[1.8px] border-solid border-white" />
							<div className="absolute -top-[22.5px] rounded-[9.24px] bg-blue-main box-border w-[100px] md:w-[107.15px] h-[142.25px] border-[1.8px] border-solid border-white" />
							<div className="absolute -top-[30px] rounded-[9.24px] bg-white box-border w-[100px] md:w-[107.15px] h-[142.25px] border-[1.8px] border-solid border-blue-main">
								<i className="absolute font-extrabold top-[calc(50%_-_14px)] whitespace-nowrap text-base mx-auto text-center w-[100px] md:text-lg text-dark-blue">
									<span>WIN-</span>
									<span className="text-blue-main">YAR</span>
								</i>
							</div>
						</div>
					</div>
					<div className="flex justify-center flex-1">
						{cardBahar && animationSide === "bahar" ? (
							<AnimatedCard
								key={`${nanoid()}-bahar`}
								card={cardBahar.val}
								anime={true}
								side="bahar"
								type={cardBahar.type}
							/>
						) : (
							cardBahar && (
								<AnimatedCard
									key={`${nanoid()}-bahar`}
									card={cardBahar.val}
									anime={false}
									side="bahar"
									type={cardBahar.type}
								/>
							)
						)}
						{cardBahar === null ? (
							<div className="relative w-[98px] md:w-[103.46px] h-[138.56px] text-lg text-gray">
								<div className="md:absolute top-[0px] left-[0px] rounded-[9.24px] bg-white w-[98px] md:w-[103.46px] h-[138.56px]" />
								<b className="absolute top-[59px] left-[calc(50%_-_25.73px)]">
									Bahar
								</b>
							</div>
						) : null}
					</div>
				</div>
				<div className="flex items-center justify-between my-4 w-80 md:w-96">
					<div className="flex w-[98px] md:w-[103.46px] flex-col items-center justify-center flex-1">
						<div
							className="rounded-mini bg-dodgerblue-100 w-[98px] md:w-[103.46px] flex flex-row py-3.5 px-5 box-border items-center justify-center text-3xl text-white"
							onClick={() => handleUserSelect("andar")}
							style={{
								opacity: !timer ? "60%" : "100%",
							}}
						>
							{userSelect === "andar" && betAmount ? (
								<>Rs.{betAmount?.toFixed()}</>
							) : (
								"Andar"
							)}
						</div>
						<div className="my-2 text-sm text-center">1:2</div>
					</div>
					<div className="flex w-[98px] md:w-[103.46px] flex-col items-center justify-center flex-1">
						<button
							className=" rounded-mini bg-orange-200 w-[98px] md:w-[103.46px] flex flex-col py-3.5 px-5 box-border items-center justify-center text-3xl text-white"
							onClick={() => handleUserSelect("tie")}
							style={{
								opacity: !timer ? "60%" : "100%",
							}}
						>
							{userSelect === "tie" && betAmount ? (
								<>Rs.{betAmount?.toFixed()}</>
							) : (
								"Tie"
							)}
						</button>
						<div className="my-2 text-sm text-center">1:9</div>
					</div>
					<div className="flex w-[98px] md:w-[103.46px] flex-col items-center justify-center flex-1">
						<button
							className={`rounded-mini ${
								!timer ? "bg-red-500" : "bg-tomato"
							} w-[98px] md:w-[103.46px] flex flex-row py-3.5 px-5 box-border items-center justify-center text-3xl text-white`}
							onClick={() => handleUserSelect("bahar")}
							style={{
								opacity: !timer ? "60%" : "100%",
							}}
						>
							{userSelect === "bahar" && betAmount ? (
								<>Rs.{betAmount?.toFixed()}</>
							) : (
								"Bahar"
							)}
						</button>
						<div className="my-2 text-sm text-center">1:2</div>
					</div>
				</div>
				<div className="flex flex-row items-center justify-center mb-1 text-sm text-white">
					Total Balance : {totalBalance?.toFixed(2)}
				</div>
			</div>
			<BetModel
				showModal={showModal}
				setShowModal={setShowModal}
				userSelect={userSelect}
			/>
			<AnadarBaharResultModal
				showModal={showResultModal}
				setShowModal={setResultShowModal}
				gameResult={gameResult}
				setGameResult={setResultShowModal}
			/>
			<AndarBaharRecords buffer={buffer} />
			<RechargeModal
				rechargeModal={rechargeModal}
				setRechargeModal={setRechargeModal}
			/>
		</>
	);

	function GrowthTags() {
		return (
			<div className="flex flex-row items-center justify-between mt-5 mb-4 text-xs w-[100%]">
				{historyData?.slice(-6)?.map((data, index) => {
					let bgColor = "";
					if (data === "andar") {
						bgColor = "bg-dodgerblue-200";
					} else if (data === "bahar") {
						bgColor = "bg-tomato";
					} else if (data === "tie") {
						bgColor = "bg-orange-100";
					}
					return (
						<div
							className={`rounded-full ${bgColor} w-[30px] h-[30px] flex flex-row py-3.5 px-1.5 md:px-2.5 box-border items-center justify-center`}
							key={index}
						>
							<div className="relative text-sm">
								{data === "andar" ? "A" : data === "bahar" ? "B" : "T"}
							</div>
						</div>
					);
				})}
				<div className="rounded-full bg-gray w-[30px] h-[30px] flex flex-row py-3.5 px-1.5 md:px-2.5 box-border items-center justify-center">
					<img
						className="relative w-[21px] h-[21px] overflow-hidden shrink-0"
						alt=""
						src="./mdiclockarrow.svg"
					/>
				</div>
			</div>
		);
	}
};

export default AndarBaharGameCards;

const AnimatedCard = ({ card, anime, side, type }) => {
	const cardFlipSoundRef = useRef(null);

	useEffect(() => {
		const playCardFlipSound = () => {
			cardFlipSoundRef.current.play().catch((error) => {
				console.error("Error playing audio:", error);
			});
		};
		playCardFlipSound();
	}, []);
	return (
		<>
			<audio
				ref={cardFlipSoundRef}
				src="./assets/cards/cardAudio.wav"
				preload="auto"
			/>
			<div
				className={`${
					anime && side === "bahar"
						? "animate-bahar"
						: anime && side === "andar"
						? "animate-andar"
						: ""
				} relative w-[98px] md:w-[103.46px] h-[138.56px] flex flex-col justify-center items-center text-center text-3xl`}
			>
				<img
					src={
						type === "h"
							? "./assets/cards/dil.svg"
							: type === "d"
							? "./assets/cards/charkat.svg"
							: type === "s"
							? "./assets/cards/kali.svg"
							: "./assets/cards/fali.svg"
					}
					alt="cards"
					width="100%"
					height="100%"
				/>

				<b className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					{card == 11
						? "J"
						: card == 12
						? "Q"
						: card == 13
						? "K"
						: card == 1
						? "A"
						: card}
				</b>
			</div>
		</>
	);
};
