import React, { useEffect, useState } from "react";
import {
	getUserBalance,
	notifyError,
	removeLocalStorage,
} from "../utils/constant";
import { useNavigate } from "react-router-dom";

import RechargeModal from "../shared/recharge-modal";
import cogoToast from "cogo-toast";
import RocketGame, { socketInstance } from "../components/aviator";
import { AviatorHistory } from "../components/aviator/aviator-history";
import AviatorLayout from "../components/aviator/aviator-layout";
import SubHeader from "../components/aviator/aviator-sub-header";
import Switch from "react-switch";
import { getDashboardData } from "../services/backendServices";

function Aviator() {
	const [activeTab, setActiveTab] = useState(0);
	const switchTab = (index) => {
		if (!autoCheckBox) {
			setActiveTab(index);
		}
	};
	const [showRulesModal, setShowRulesModal] = useState(false);
	//bet value
	const [betValue, setBetValue] = useState(20);
	const [currValue, setCureeValue] = useState(20);
	const [confirmBet, setConfirmBet] = useState(false);
	const [timer, setTimer] = useState();
	const [gameResult, setGameResult] = useState();
	const [betAmount, setBetAmount] = useState(0);
	//selected amount button border color
	const [selectedValue, setSelectedValue] = useState(20);

	// auto check
	const [autoCheckBox, setAutoCheckBox] = useState(false);
	const [autoStopValue, setAutoStopValue] = useState("1.0");

	//model
	const [showModal, setShowModal] = useState(false);
	const [rechargeModal, setRechargeModal] = useState(false);

	// resulte header
	const [buffer, setBuffer] = useState([]);
	const [totalBalance, setTotalBalance] = useState();
	const navigate = useNavigate();

	const fetchData = async () => {
		try {
			const res = await getDashboardData();
			if (res.status) {
				setTotalBalance(Number(res.user_balance));
			} else {
				notifyError(res.msg);
			}
		} catch (error) {
			console.log("error in fetch dashbordata", error);
		}
	};

	const [rocketHeight, setRocketHieght] = useState(1);

	useEffect(() => {
		if (socketInstance) {
			socketInstance?.on("resBettingTimeStart", () => {
				setTimer(true);
			});

			socketInstance?.on("resBet", (res) => {
				// console.log("data", res.data.amount);
				if (res.data.status) {
					setTotalBalance(
						(prevBalance) => prevBalance - Number(res.data.amount)
					);
					setConfirmBet(true);
				}
				if (res.data.msg === "Insufficient balance") {
					setConfirmBet(false);
					setRechargeModal(true);
				}
			});

			socketInstance?.on("resBettingTimeEnd", () => {
				setTimer(false);
			});

			socketInstance?.on("resGameState", (res) => {
				// console.log('resGameState event:', data)
				setTimer(Boolean(res.data.isBettingOn));
				setBuffer(res.data.aviator_latest_histories);
			});

			socketInstance?.on("resStop", (res) => {
				// console.log("resulte", res);
				if (res.data.status) {
					setBetAmount(0);
					setGameResult(res.data.amount);
					if (res.data.amount > 0) {
						cogoToast.success(`WIN : Rs. ${res.data.amount}`);
						fetchData();
					} else {
						cogoToast.warn(`LOSE : Rs. ${res.data.amount}`);
					}
					// setShowModal(true);
				}
				setConfirmBet(false);
			});
			socketInstance?.on("aviator_latest_records", (res) => {
				setBuffer(res);
			});
			socketInstance?.on("remove_user", () => {
				removeLocalStorage();
				setTimeout(() => {
					navigate("/login");
				}, 500);
			});

			socketInstance?.on("resRocketHeight", (data) => {
				const rocketHeight = Number(data.data.roketHeight);
				if (rocketHeight) {
					setRocketHieght(rocketHeight);
				}
			});
		}

		// // Cleanup the event listener
		return () => {
			socketInstance.off("resBettingTime");
			socketInstance.off("resRocketBlast");
		};
	}, []);

	useEffect(() => {
		//currently we not get any data of isAuto status that why i made request of false ,
		// possible to true valuye in server
		if (socketInstance) {
			socketInstance?.emit("reqSetAutoStop", {
				isAutoStopOn: false,
				autoStopLimit: 0,
			});
		}

		const balance = getUserBalance();
		setTotalBalance(Number(balance));
	}, []);

	// handle battting value
	const handleChange = (event) => {
		const newValue = parseInt(event.target.value);
		setBetValue(newValue);
	};

	// handle the bet value
	const handleBetValue = (value) => {
		setCureeValue(value);
		setSelectedValue(value);
		setBetValue((prevVal) => prevVal + value);
	};

	// handle minus button
	const handleDecrementValue = () => {
		if (betValue - currValue >= 20) {
			setBetValue((prevVal) => prevVal - currValue);
		} else {
			setBetValue(20);
		}
	};

	//handle the Auto betting
	const handleAutoStopValue = (e) => {
		setAutoCheckBox(false);
		socketInstance?.emit("reqSetAutoStop", {
			isAutoStopOn: false,
			autoStopLimit: 0,
		});
		const value = parseFloat(e.target.value);
		if (!isNaN(value) && value >= 1) {
			setAutoStopValue(value);
		} else {
			setAutoStopValue();
		}
	};
	const [error, setError] = useState("");
	const handleAutoBet = () => {
		if (autoStopValue === undefined || autoStopValue < 1) {
			setError("Enter valid autostop input value");
			setTimeout(() => {
				setError();
			}, 2000);
		}
		// console.log(isNaN(autoStopValue), autoStopValue, autoCheckBox);
		if (!isNaN(autoStopValue)) {
			setError();
			// console.log("yes", !autoCheckBox, autoStopValue);
			socketInstance?.emit("reqSetAutoStop", {
				isAutoStopOn: !autoCheckBox,
				autoStopLimit: autoStopValue,
			});
			setAutoCheckBox(!autoCheckBox);
		} else {
			socketInstance?.emit("reqSetAutoStop", {
				isAutoStopOn: false,
				autoStopLimit: 0,
			});
			setAutoStopValue();
			setAutoCheckBox(false);
		}
	};

	const handleBet = () => {
		if (!isNaN(betValue) && betValue > 19) {
			setBetAmount(betValue);
			socketInstance?.emit("reqBet", {
				bettingAmount: betValue,
			});
		} else {
			setBetValue(20);
		}
	};

	const handleStop = () => {
		socketInstance?.emit("reqStop", {});
		setConfirmBet(false);
	};

	// const condition = (!timer && !confirmBet) || (timer && confirmBet);

	const [buttonIsDeabled, setButtonIsDeabled] = useState(false);

	useEffect(() => {
		if (
			(confirmBet === true && timer === true) ||
			(confirmBet === false && timer === false)
		) {
			setButtonIsDeabled(true);
		} else if (confirmBet === false && timer === true) {
			setButtonIsDeabled(false);
		}
	}, [confirmBet, timer]);

	return (
		<>
			<AviatorLayout>
				<SubHeader />
				<div
					className="container"
					style={{ height: "100%", width: "100%", background: "black" }}
				>
					<div style={{ overflowX: "hidden", maxWidth: "100vw" }}>
						<div className="flex mt-3 justify-between">
							{buffer.slice(-5)?.map((data, index) => {
								let bgColor = "";
								if (data > 1.5 && data <= 2) {
									bgColor = "indigo-color";
								} else if (data < 1.5) {
									bgColor = "sky-color";
								} else if (data > 2) {
									bgColor = "high-color";
								}
								return (
									<div
										key={index}
										className={`sky-color ${bgColor} font-weight-bold rounded-full px-2 py-1 m-2 custom-pill`}
									>
										{data === 0 ? "0.00" : Number(data)?.toFixed(2)}x
									</div>
								);
							})}
							<div className="indigo-color font-weight-bold rounded-full px-3 py-1 m-2 custom-pill">
								<img src="./history.svg" alt="n" height={8}></img>
							</div>
						</div>
					</div>

					<RocketGame />

					<div
						className="card text-center py-3 "
						style={{
							width: "auto",
							background: "#171716",
							color: "white",
							borderRadius: "15px",
						}}
					>
						<div className="container flex justify-center">
							<div
								className="custom-tabs mb-1"
								style={{
									backgroundColor: "black",
									opacity: autoCheckBox ? "0.5" : "1",
								}}
							>
								<div
									className={`custom-tab ${activeTab === 0 ? "active-tab" : "inactive-tab"
										}`}
									onClick={() => switchTab(0)}
									style={{
										opacity: autoCheckBox ? "0.5" : "1",
									}}
								>
									Bet
								</div>
								<div
									className={`custom-tab ${activeTab === 1 ? "active-tab" : "inactive-tab"
										}`}
									onClick={() => switchTab(1)}
									style={{
										opacity: autoCheckBox ? "0.5" : "1",
									}}
								>
									Auto
								</div>
							</div>
						</div>
						<div className="flex m-2">
							<div className="w-5/12 pr-0">
								<div className="rounded-full input-group-rounded-pill flex justify-center items-center p-1">
									<div className="flex justify-center items-center ml-1 ">
										<button
											className="btn rounded-full bg-black"
											type="button"
											onClick={handleDecrementValue}
										>
											-
										</button>
									</div>
									<input
										type="number"
										className="form-control font-bold bg-black mx-1 text-center px-0 text-white"
										style={{ textAlign: "center", width: "80%" }}
										value={betValue}
										onChange={handleChange}
									/>
									<div className="flex justify-center items-center mr-1">
										<button
											className="btn rounded-full bg-black text-white"
											style={{ paddingTop: "2px" }}
											type="button"
											onClick={() =>
												setBetValue((prevVal) => prevVal + currValue)
											}
										>
											+
										</button>
									</div>
								</div>
								<div className="mt-1 flex justify-center items-center">
									<div
										className="w-5/12 rounded-full bg-black mr-2 text-sm"
										onClick={() => handleBetValue(50)}
										style={{
											color: "#ffffff80",
											border:
												selectedValue === 50 ? "1px solid orange" : "none", // If value is 20, set border to 2px solid orange; otherwise, no border
										}}
									>
										50
									</div>

									<div
										className="w-5/12 rounded-full text-sm bg-black"
										onClick={() => handleBetValue(100)}
										style={{
											color: "#ffffff80",
											border:
												selectedValue === 100 ? "1px solid orange" : "none", // If value is 20, set border to 2px solid orange; otherwise, no border
										}}
									>
										100
									</div>
								</div>
								<div className="mt-1 flex justify-center items-center">
									<div
										className="w-5/12 rounded-full text-sm bg-black mr-2"
										onClick={() => handleBetValue(500)}
										style={{
											color: "#ffffff80",
											border:
												selectedValue === 500 ? "1px solid orange" : "none", // If value is 20, set border to 2px solid orange; otherwise, no border
										}}
									>
										500
									</div>
									<div
										className="w-5/12 rounded-full text-sm bg-black"
										onClick={() => handleBetValue(1000)}
										style={{
											color: "#ffffff80",
											border:
												selectedValue === 1000 ? "1px solid orange" : "none", // If value is 20, set border to 2px solid orange; otherwise, no border
										}}
									>
										1000
									</div>
								</div>
							</div>
							<div className="w-7/12 flex items-center mx-2">
								{confirmBet && timer === false ? (
									<button
										className="aviator-cashout-btn btn w-full rounded-lg font-bold"
										type="button"
										onClick={handleStop}
										style={{ height: "95%" }}
									>
										<div>CASH OUT</div>
										<div>
											Rs. {(Number(betAmount) * rocketHeight)?.toFixed(2)}{" "}
										</div>
									</button>
								) : (
									<button
										className={`${confirmBet ? "aviator-cashout-btn" : "aviator-bet-btn"
											} btn w-full rounded-lg font-bold`}
										type="button"
										onClick={handleBet}
										style={{
											height: "95%",
											opacity: buttonIsDeabled ? "0.6" : "1",
										}}
										disabled={buttonIsDeabled}
									>
										<div>{confirmBet ? "CASH OUT" : "BET"}</div>
										<div>{!isNaN(betValue) && `Rs. ${betValue}`} </div>
									</button>
								)}
							</div>
						</div>

						{activeTab === 1 && (
							<div className="flex g-0 mx-2">
								{/* <div style={{ border: "1px black solid" }}></div> */}
								<div className="flex mt-2 justify-content-around items-center text-sm text-white">
									<div className="w-7/12 flex items-center gap-3 ml-3">
										<label
											for="autoSwitch"
											className="form-check-labe font-bold"
											style={{ fontSize: "12px" }}
										>
											AUTO CASH OUT
										</label>
										<div className="form-check form-switch">
											<Switch
												id="autoSwitch"
												onChange={handleAutoBet}
												checked={autoCheckBox}
												onColor="#28a909"
												checkedIcon={false}
												uncheckedIcon={false}
												height={18}
												width={40}
											/>
										</div>
									</div>
									<div className="w-5/12 g-0 flex justify-center">
										<div
											className="input-group input-group-rounded-pill rounded-full"
											style={{ width: "70%" }}
										>
											<div className="flex justify-center items-center ml-3"></div>
											<input
												type="number"
												value={autoStopValue}
												placeholder="x.xx"
												onChange={handleAutoStopValue}
												className="form-control font-weight-bold bg-black mx-1 text-center rounded-full"
												style={{
													textAlign: "center",
													height: "25px",
													width: "100%",
												}}
											/>

											<div className="input-group-append flex justify-center items-center mr-3"></div>
										</div>
									</div>
								</div>
								{/* <p className="m-0 text-center text-danger text-xs">erroe msg</p> */}
							</div>
						)}
					</div>
					<div className="flex justify-center my-3">
						<AviatorHistory />
					</div>
				</div>

				<RechargeModal
					rechargeModal={rechargeModal}
					setRechargeModal={setRechargeModal}
				/>
			</AviatorLayout>
		</>
	);
}

export default Aviator;
