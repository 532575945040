import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RechargeModal from "../../shared/recharge-modal";
import UserBlockModel from "../../shared/user-block-model";
import { checkUserBlockStatus } from "../../utils/constant";

const GamesSection = ({ title, games }) => {
	const navigate = useNavigate();
	const [isBlock, setIsBlock] = useState(false);
	const isBlocked = checkUserBlockStatus();
	return (
		<section className="my-4 text-lg">
			<b className="block mb-3">{title}</b>
			<div className="grid grid-cols-2 gap-4 overflow-x-auto text-base">
				{games?.map((game, index) => (
					<div key={index} className="relative flex flex-col items-center">
						<img
							className={`rounded-xl w-[163px] h-[163px] object-cover  ${
								game.link !== "/dashboard" ? "shadow-md shadow-white/20" : null
							}`}
							alt=""
							src={game.image}
							draggable="false"
							onClick={() =>
								isBlocked ? setIsBlock(true) : navigate(game.link)
							}
						/>
						<b className="mt-2">{game.name}</b>
					</div>
				))}
			</div>

			<UserBlockModel isBlock={isBlock} setIsBlock={setIsBlock} />
		</section>
	);
};

export default GamesSection;
