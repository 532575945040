import React from "react";

const UserBlockModel = ({ isBlock, setIsBlock }) => {
	return (
		<>
			{isBlock ? (
				<>
					<div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
						<div className="relative w-[90%] md:max-w-3xl md:mx-auto md:my-6">
							<div className="relative p-3 border-0 rounded-lg shadow-lg outline-none md:w-full rounded-t-xl bg-darkslategray focus:outline-none">
								<div className="flex items-start justify-between p-3 border-b border-gray-300 border-solid rounded-t ">
									<h3 className="text-3xl m-0 font=semibold text-white text-center">
										<b className="">User Information</b>
									</h3>
									<button
										className="float-right text-black bg-transparent border-0"
										onClick={() => setIsBlock(false)}
									>
										<span className="block w-6 h-6 py-0 text-xl text-white bg-gray-400 rounded-full opacity-7">
											x
										</span>
									</button>
								</div>

								<div className="text-lg ">
									<div className=" box-border w-full h-px border-t-[1px] border-solid border-dark-blue" />
									<div className="m-2 flex justify-center">
										<div className="text-red-500 text-3xl">
											<b>Your Account Is Blocked !</b>
										</div>
									</div>
									<div className="flex justify-center">
										<div className="text-white mb-2 text-md">
											Contact Support Team.
										</div>
									</div>

									<div className="m-2 flex flex-row items-start justify-center gap-[5px] text-center text-base text-lightslategray">
										<div
											className="rounded-8xs bg-blue-main w-[90px] h-[40px] flex flex-row py-3.5 px-5 box-border items-center justify-center text-black"
											onClick={() => setIsBlock(false)}
										>
											<b className="relative leading-[130.4%]">OK</b>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

export default UserBlockModel;
