import { useEffect, useState } from "react";
import { formatTime } from "../../shared/format-time";
import { socketInstance } from "../aviator";
import React from "react";
import { useNavigate } from "react-router-dom";

import { notifyError } from "../../utils/constant";
import { fetchGameHistory } from "../../services/backendServices";

export const AviatorHistory = () => {
	const [gameHistory, setGameHistory] = useState([]);

	// everyone's order
	const [buffer, setBuffer] = useState([]);
	const bufferSize = 6;

	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState("everyone");
	useEffect(() => {
		const gameName = "aviator";

		fetchGameHistory(gameName)
			.then((res) => {
				if (res.status) {
					notifyError(res.msg);
				} else {
					setGameHistory(res.game_data);
				}
			})
			.catch((error) => {
				console.error("Error fetching andarbahar game history:", error);
			});
	}, [activeTab]);

	useEffect(() => {
		const socket = socketInstance;

		const handleAviatorResults = (res) => {
			addToBuffer(res);
		};

		const handleGameState = (res) => {
			if (!res.data.isRoketOn) {
				const data = res.data.result_history.slice(1, 6);
				setBuffer(data);
				// console.log("res", res);
			}
		};

		if (socket) {
			socket.on("aviator_results", handleAviatorResults);
			socket.on("resGameState", handleGameState);
		}

		return () => {
			// Cleanup: Remove the event listener when the component unmounts
			if (socket) {
				socket.off("aviator_results", handleAviatorResults);
				socket.off("resGameState", handleAviatorResults);
			}
		};
	}, []);

	const addToBuffer = (newValue) => {
		// If the buffer is already at its maximum size, remove the oldest value from the front
		if (buffer?.length >= bufferSize) {
			setBuffer((prevBuffer) => [...prevBuffer.slice(1), newValue]);
		} else {
			setBuffer((prevBuffer) => [...prevBuffer, newValue]);
		}
	};

	if (buffer?.length > bufferSize) {
		setBuffer((prevBuffer) => [...prevBuffer.slice(1)]);
	}

	return (
		<div
			className="relative text-xs text-center text-white rounded-xl font-fs-elliot-pro mb-5"
			style={{ background: "#171716", height: "100%", width: "98%" }}
		>
			<div className="flex items-center text-sm text-left ">
				<div
					className={`flex-1 box-border flex flex-row p-3.5 items-center justify-center border-b-[2px] border-solid ${
						activeTab === "everyone" ? "border-blue-main" : "border-dark-blue"
					}`}
					onClick={() => setActiveTab("everyone")}
				>
					<div className="relative">Everyone orders</div>
				</div>
				<div
					className={`flex-1 box-border  flex flex-row p-3.5 items-center justify-center border-b-[2px] border-solid ${
						activeTab === "myOrders" ? "border-blue-main" : "border-dark-blue"
					}`}
					onClick={() => setActiveTab("myOrders")}
				>
					<div className="relative">My orders</div>
				</div>
			</div>

			{activeTab === "everyone" ? (
				<div className="w-[100%] mb-10">
					<div className="rounded-xl flex flex-col  items-center justify-center text-xs">
						{buffer?.length === 0 ? null : (
							<div className="flex flex-row w-[90%] py-[5px] px-0 items-center justify-center border-b-[1px] border-solid border-white">
								<div className="w-[100px] flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
									<b className="relative">Period</b>
								</div>
								<div className="w-[69px] flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
									<b className="relative">User</b>
								</div>
								<div className="w-[69px] flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
									<b className="relative">Height</b>
								</div>
								<div className="w-[69px] flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
									<b className="relative">Win</b>
								</div>
							</div>
						)}

						{buffer?.length === 0 ? (
							<div className="my-5">No records</div>
						) : (
							buffer?.map((data, index) => {
								return (
									<React.Fragment key={index}>
										<div className="flex flex-row py-[5px] px-0 items-center justify-center">
											<div className="w-[100px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
												<div className="relative">{data.game_time}</div>
											</div>
											<div className="w-[69px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
												<div className="relative">{data.user_name}</div>
											</div>
											<div className="w-[69px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
												<div className="relative">
													{data.rocket_height?.toFixed(2)}X
												</div>
											</div>
											{data.winning_amount && (
												<div className="w-[69px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
													<div className="relative text-lightgreen">
														+{data.winning_amount?.toFixed(2)}
													</div>
												</div>
											)}{" "}
											{data.losing_amount && (
												<div className="w-[69px] h-8 flex flex-row py-1.5 px-3.5 box-border items-center justify-center">
													<div className="relative text-tomato">
														{data.losing_amount?.toFixed(2)}
													</div>
												</div>
											)}
										</div>
									</React.Fragment>
								);
							})
						)}
					</div>
				</div>
			) : (
				<div className="flex flex-col items-center justify-center p-4 text-center ">
					{gameHistory?.length === 0 ? (
						<div className="mt-5 text-xs flex justify-center">No records</div>
					) : (
						gameHistory?.map((current, index) => {
							return (
								<div
									className="relative flex rounded-xl p-4 gap-3 m-2 border-[1px] border-solid border-white w-[90%]"
									key={index}
								>
									<div className="flex-1 w-1/2">
										<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap">
											<div className="relative">
												<span>{`Result: `}</span>
												<b
													className={`${
														current.is_win ? "text-lightgreen" : "text-tomato"
													}`}
												>
													{current.is_win ? "win" : "lose"}
												</b>
											</div>
										</div>
										<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap">
											<div className="relative">
												<span>{`Time: `}</span>
												<b>{formatTime(current.end_time)}</b>
											</div>
										</div>
										<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap">
											<div className="relative">
												<span>{`point: `}</span>
												<b>{current.betting_amount?.toFixed()}</b>
											</div>
										</div>
									</div>
									<div className="flex-1 w-1/2">
										<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap">
											<div className="relative">
												<span>{`Amount: `}</span>
												<b
													className={` ${
														current.is_win ? "text-lightgreen" : "text-tomato"
													}`}
												>
													{current.is_win
														? `+ ${current.winning_amount?.toFixed(2)}`
														: `- ${current.betting_amount?.toFixed(2)}`}
												</b>
											</div>
										</div>
										<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap ">
											<div className="relative">
												<span>{`Stop: `}</span>
												<b>
													{current.is_win
														? current.rocket_height?.toFixed(2)
														: "0.00"}
												</b>
											</div>
										</div>
										<div className="flex flex-row items-center justify-start m-1 whitespace-nowrap">
											<div className="relative">
												<span>{`Blast: ${current.game_rocket_height?.toFixed(
													2
												)}`}</span>
											</div>
										</div>
									</div>
								</div>
							);
						})
					)}
				</div>
			)}
		</div>
	);
};
