import React from "react";

const LudoRulesModal = ({ ludoRulesModal, setLudoRulesModal }) => {
    const handleNavigate = () => {
        setLudoRulesModal(false);
    };

    return (
        <>
            {ludoRulesModal ? (
                <>
                    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                        <div className="relative w-[90%] md:max-w-3xl md:mx-auto md:my-6">
                            <div className="relative p-3 border-0 rounded-lg shadow-lg outline-none md:w-full rounded-t-xl bg-darkslategray focus:outline-none">
                                <div className="flex items-start justify-between p-3 border-b border-gray-300 border-solid rounded-t ">
                                    <h3 className="text-3xl m-0 font-semibold text-white text-center">
                                        <b>Ludo Rules</b>
                                    </h3>
                                    <button
                                        className="float-right text-black bg-transparent border-0"
                                        onClick={() => setLudoRulesModal(false)}
                                    >
                                        <span className="block w-6 h-6 py-0 text-xl text-white bg-gray-400 rounded-full opacity-7">
                                            x
                                        </span>
                                    </button>
                                </div>

                                <div className="overflow-y-auto max-h-[50vh] text-lg">
                                    <div className="box-border w-full h-px border-t-[1px] border-solid border-dark-blue" />

                                    <div className="m-1 text-white">
                                        <h4 className="text-xl font-bold">Common Rules</h4>
                                        <ul>
                                            <li>Only one winner per game.</li>
                                            <li>Each player has 5 lifelines.</li>
                                            <li>If a player does not make a move when it's their turn, they lose a lifeline.</li>
                                            <li>If a player does not make a dice roll when it's their turn, they lose a lifeline.</li>
                                            <li>If a player loses all their lifelines, the remaining player(s) are declared the winner by default.</li>
                                            <li>Lifelines are displayed below the dice box: green circle for remaining lives, red circle for lost lives.</li>
                                        </ul>

                                        <h4 className="text-xl font-bold mt-4">Play with Friends</h4>
                                        <ul>
                                            <li>Create a Room: One player generates a unique room code.</li>
                                            <li>Join a Room: Other players join using the unique code.</li>
                                            <li>Player Limit: 2 to 4 players.</li>
                                            <li>Game Start: The creator starts the game once all players have joined.</li>
                                            <li>Gameplay: Follow standard Ludo rules to move pieces to the home area.</li>
                                        </ul>

                                        <h4 className="text-xl font-bold mt-4">Play Online</h4>
                                        <ul>
                                            <li>Select Mode: Choose between a 2-player or 4-player game.</li>
                                            <li>Choose Content</li>
                                            <li>Game Start: The game starts automatically once matched.</li>
                                            <li>Gameplay: Follow standard Ludo rules to move pieces to the home area.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mt-2"></div>
                                <div className="m-2 flex flex-row items-start justify-center gap-[5px] text-center text-base text-lightslategray">
                                    <div
                                        className="rounded-8xs bg-wingreen w-[180px] h-[40px] flex flex-row py-1.5 px-5 box-border items-center justify-center text-white cursor-pointer"
                                        onClick={handleNavigate}
                                    >
                                        <b className="relative leading-[130.4%]">
                                            Play Now
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default LudoRulesModal;
