import React, { useState } from "react";
import { Link } from "react-router-dom";

const SubHeader = ({ backLink = "/dashboard" }) => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<div className="aviator-sub-header bg-black h-[45px] flex flex-row items-center justify-between gap-[78px] text-xl text-white z-[1000]">
				<Link to={backLink}>
					<div className="h-[45px] overflow-hidden flex flex-row p-2 box-border items-center justify-center">
						<img
							className="relative w-2.5 h-[17.47px]"
							alt=""
							src="./vector5.svg"
						/>
					</div>
				</Link>
				<b className="relative text-base whitespace-nowrap">Aviator</b>
				<div
					className="h-[45px] flex flex-row p-2 box-border items-center justify-center text-xs"
					onClick={() => setShowModal(true)}
				>
					<div className="relative" style={{ fontSize: "20px" }}>
						ⓘ&nbsp; &nbsp;
					</div>
				</div>
			</div>
			<RuleModel showModal={showModal} setShowModal={setShowModal} />
		</>
	);
};

export default SubHeader;

const RuleModel = ({ showModal, setShowModal }) => {
	return (
		<>
			{showModal ? (
				<div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto ">
					<div className="max-w-[80%] relative md:max-w-3xl md:mx-auto md:my-6">
						<div className="relative p-3 border-0 rounded-lg shadow-lg outline-none md:w-full rounded-t-xl bg-black focus:outline-none">
							<div className="flex items-start justify-between pb-2 px-3 border-b border-zinc-600 border-solid rounded-t">
								<h3 className="text-xl m-0 font-semibold text-white text-center">
									<b className="">Join Aviator</b>
								</h3>
								<button
									className="float-right text-white bg-transparent border-0"
									onClick={() => setShowModal(false)}
								>
									<span className="block w-6 h-6 py-0 text-xl text-white bg-gray-400 rounded-full opacity-7">
										x
									</span>
								</button>
							</div>

							<div className="text-lg text-white">
								<div className="box-border w-full h-px border-t-[1px] border-solid border-dark-blue" />
								<div className="max-h-[300px] overflow-y-auto p-3">
									<div className="text-sm text-justify">
										<div className="text-sm text-justify">
											Aviator is a{" "}
											<b>
												game that multiply your Money with the X multiplier, it
												can be 1X, 5X, 10X etc.
											</b>
											<br />
											<br />
											<ul className="p-[10px] m-0">
												<li className="mb-2">
													First, choose how much you want to place Order. Click{" "}
													<b>“BET”</b> to place your order. watch the multiplier
													rise from X upwards!
												</li>
												<li className="mb-2">
													You can click <b>“Cash Out”</b> at any time in order
													to multiply your Money with the current X multiplier.
												</li>
											</ul>
											The game can end at any time, and if you haven’t stop by
											then, you get nothing!
										</div>
									</div>
								</div>
								<div
									className="m-2 rounded-3xs bg-wingreen h-[38px] flex flex-row py-3.5 px-5 box-border items-center justify-center text-center text-base"
									onClick={() => setShowModal(false)}
								>
									<b className="relative leading-[130.4%]">Play Now</b>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};
